import { httpToken } from "@/utils/request";
import {configu} from '@/utils/config'//引入公用文件
import router from "../../router";
import qs from 'qs'
const scope = 'server'
// 用户名密码登录 用户名 密码 
export const loginByUsername = (username, password) => {
  const grant_type = 'password'
  const dataObj = qs.stringify({ 'username': username, 'password': password })
  const basicAuth = 'Basic ' + window.btoa(configu.formLoginClient)
  // params 参数
  // code 验证码
  // randomStr：短信验证码
  // scope：域 与app作范围区分
  return httpToken({
    url: configu.serverURL+'/auth/oauth2/token',
    headers: {
      isToken: false,
      Authorization: basicAuth
    },
    method: 'post',
    params: { scope,grant_type },
    data: dataObj
  })
}
// 用户名密码登录 用户名 密码 
export const loginByCode = (phone, code) => {
  const grant_type = 'app'
  const basicAuth = 'Basic ' + window.btoa(configu.formLoginClient)
  return httpToken({
    url: configu.serverURL+'/auth/oauth2/token?phone='+phone+'&code='+code+'&grant_type='+grant_type+'&scope='+scope,
    headers: {
      isToken: false,
      Authorization: basicAuth
    },
    method: 'post',
  })
}
// 退出登录
export const loginOut = () => {
  return httpToken({
    url: configu.serverURL+'/auth/token/logout',
    method: 'post',
  }).then(res=>{
    router.go(0);
    window.sessionStorage.removeItem("token");
    window.sessionStorage.removeItem("user");
    window.sessionStorage.removeItem("RouteInfo");
    window.sessionStorage.removeItem("MENU");
    window.sessionStorage.removeItem("MENU2");
    window.sessionStorage.removeItem("buttonPermissions");
    router.replace({ path: "/login" });
  })
}
// 校验令牌，若有效期小于半小时自动续期
// 定时任务请求后端接口返回实际的有效时间，不进行本地计算避免 客户端和服务器机器时钟不一致
export const checkToken = (refreshLock, $store) => {
  // const token = store.getters.token
  // // 获取当前选中的 basic 认证信息
  // const basicAuth = getStore({ name: 'basicAuth' })
  // if (validatenull(token) || validatenull(basicAuth)) {
  //   return
  // }
  // request({
  //   url: '/auth/token/check_token',
  //   headers: {
  //     isToken: false,
  //     Authorization: basicAuth
  //   },
  //   method: 'get',
  //   params: { token }
  // }).then(response => {
  //   const expire = response && response.data && response.data.exp
  //   if (expire) {
  //     const expiredPeriod = expire * 1000 - new Date().getTime()
  //     console.log('当前token过期时间', expiredPeriod, '毫秒')
  //     //小于半小时自动续约
  //     if (expiredPeriod <= website.remainingTime) {
  //       if (!refreshLock) {
  //         refreshLock = true
  //         $store.dispatch('RefreshToken')
  //           .catch(() => {
  //             clearInterval(this.refreshTime)
  //           })
  //         refreshLock = false
  //       }
  //     }
  //   }
  // }).catch(error => {
  //   console.error(error)
  // })
}