const module = {
  namespaced: true,//带命名空间 namespaced: true 的模块，所有功能区分模块，更高封装度和复用
  state: {
    templateDatas:{},//模板缓存数据
    fileLoading:false,//签约文件loading
  },
  mutations: {
    resetData(state){
      state.templateDatas={}
    },
    settemplateDatas(state,data){
      state.templateDatas=data
    },
    setfileLoading(state,data){
      state.fileLoading=data
    },
  },
  actions: {}
}

export default module