import { httpToken } from "@/utils/request";
import qs from "qs";
import store from "@/store/index";
// 获取按钮权限
export function getbutpermission() {
    return httpToken({
      method: "post",
      url: '/admin/sys-user/getPermissions',
    //   data: qs.stringify({
    //     paramData: JSON.stringify(json),
    //   }),
    }).then((res) => {
      // console.log('获取按钮权限',res)
      store.commit("SET_BUTTONPERMISSIONS", res.data);
      window.sessionStorage.setItem("buttonPermissions",JSON.stringify(res.data));
    })
}