<template>
  <div class="realnameIcon dp-fc" :style="{ width: width + 'px', height: height + 'px' }"
    :class="[props.is ? 'realnameIconac' : 'cu-p', 'realnameIcon' + CSStype, 'IMGtype' + props.IMGtype]"
    @click="approve()">
    <template v-if="props.is">
      <img src="./img/approve.png">
      已认证
    </template>
    <template v-else>
      <img src="./img/approve2.png">
      未认证
    </template>
  </div>
</template>
<script setup>
import { reactive, ref, unref, defineProps } from "vue";
import { handleMessage } from "@/utils/server/confirm.js"
import {router_push_name} from "@/utils/server/router";
import { useStore } from "vuex";
const store = useStore();
const props = defineProps({
  is: {
    //是否实名
    type: Boolean,
    default: () => {
      return false
    }
  },
  width: {
    type: Number,
    default: () => { return 66 }
  },
  height: {
    type: Number,
    default: () => { return 22 }
  },
  companyId: {
    default: false
  },
  companyName: {
    default: false
  },
  // type 1个人 2公司
  type: {
    type: Number,
    default: 0
  },
  // css 类型
  CSStype: {
    type: Number,
    default: 1
  },
  IMGtype: {
    type: Number,
    default: 1
  },
});
// 认证跳转
const approve = () => {
  if(props.is||props.type==0){
    return
  }
  let userInfo = JSON.parse(window.sessionStorage.user).user_info
  // type 1个人 2公司
  if(props.type==2){
    // console.log('1111',props.companyId)
    if (userInfo.isCertification == 0) {
      handleMessage('请先完成个人实名认证')
      return
    }
    store.commit('realName/resetData')
    store.commit('realName/setType', 2)
    if(props.companyId){
      store.commit('realName/setCompanyId', props.companyId)
      store.commit('realName/setToCompanyName', props.companyName)
    }else{
      store.commit('realName/setCompanyId', userInfo.sysCompany.companyId)
      store.commit('realName/setToCompanyName', userInfo.sysCompany.companyName)
    }
    router_push_name('realname_boot')
  }else if(props.type==1){
    store.commit('realName/resetData')
    store.commit('realName/setType',1)
    router_push_name('realname_boot')
  }
}
</script>
<style lang="scss" scoped >
.realnameIcon {
  border-radius: 4px;
  background: rgba(138, 138, 138, 0.1);
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #959595;

  img {
    width: 14px;
    height: 14px;
    margin-right: 2px;
  }
}

.realnameIconac {
  background: #FFFFFF;
  color: #2890FF;
}

.realnameIcon2.realnameIconac {
  background: rgba(24, 144, 255, 0.1);
  color: #1890FF;
}

.realnameIcon3.realnameIconac {
  background: #E1F0FE;
  color: #1890FF;
}

.realnameIcon4 {
  background: rgb(233, 233, 233);
}
.realnameIcon5.realnameIconac{
  box-shadow: 0px 2px 6px 0px #EDEDED;
}
.IMGtype2 {
  img {
    width: 16px !important;
    height: 16px !important;
  }
}</style>