import store from "@/store/index"
import { nextTick } from 'vue'
export default (app) => {
    // v-permission="'sys:auth:save'"
    app.directive('permission', (el, binding, vnode) => {
        // console.log('自定义指令执行', binding.value)
        if (store.state.buttonPermissions.length && store.state.buttonPermissions.includes(binding.value)) {
            // console.log('有权限')
        } else {
            nextTick(() => {
                el.remove()
            })
        }
    })
}