import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 统用样式
import '@/styles/css/index.css'
// 公共样式
import "./styles/index.scss";
import "@/styles/color/index.scss";
import 'element-plus/dist/index.css'
import ElementPlus from "element-plus";
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'dayjs/locale/zh-cn' // 日历头部中文
// 全局公共属性配置
import overall from '@/utils/overall'
// 全局组件的配置
import tablebox from "@/components/layout/tablebox.vue"
import titlebox from "@/components/layout/titlebox.vue"
import searchbox from "@/components/layout/searchbox.vue"
import tableLayout from "@/components/layout/table_layout.vue"
import oabutton from "@/components/button/button.vue"

import directives from '@/utils/directive.js'
import "@/utils/server/watermark.js"

const app = createApp(App)
directives(app)
app
  .use(store)
  .use(router)
  .use(ElementPlus, {
    locale: zhCn,
  })
  // 全局属性
  .use(overall)
  // 注册全局组件
  .component('tablebox', tablebox)
  .component('titlebox', titlebox)
  .component('searchbox', searchbox)
  .component('tableLayout', tableLayout)
  .component('oabutton', oabutton)
  .mount("#app")

  