<template>
  <el-dialog class="warningbox" v-model="dialogVisible" :close-on-click-modal="false" width="328px">
    <template #header="{ close, titleId, titleClass }">
      <div class="dp-f headerbox ai-c">
        <img src="@/assets/img/general/warning.png" class="w-16 h-16 mr-8">
        {{ dialogVisibletitle }}
      </div>
    </template>
    <div class="w100 h-130 dp-fc" v-if="dialogVisibletitle === '当前企业已被认证'">
      是否要退出该企业信息?
    </div>
    <div class="warning" v-else>
      <div class="title mb-14">认证通过后可开启：</div>
      <div class="dp-f ai-c interestItem" v-for="(el, ind) in state.interests" :key="ind">
        <div class="icon mr-7"></div>
        <div>{{ el }}</div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton style="border-radius: 2px;" class="searcML" width='66' height='32' title="退出" CSStype=2
          @buttonclick="check()" v-if="dialogVisibletitle === '当前企业已被认证'"
          v-model:loading="dialogloading" />
        <oabutton style="border-radius: 2px;" class="searcML" width='66' height='32' title="去认证" CSStype=2
          @buttonclick="approve()" v-else-if="dialogVisibletitle === '当前企业未认证' || dialogVisibletitle === '当前个人未认证'"
          v-model:loading="dialogloading" />
        <oabutton style="border-radius: 2px;" class="searcML" width='66' height='32' title="取消" CSStype=1
          @buttonclick="dialogVisible = false" />
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick } from 'vue'
import { handleMessage } from "@/utils/server/confirm";
import { httpToken } from "@/utils/request";
import { router_push_name } from "@/utils/server/router";
import { resetUserInfo, resetUserMenu } from "@/api/user"
import { getbutpermission } from "@/utils/base/permission.js"
import { useStore } from "vuex";
import qs from "qs";
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogVisible = ref(false)//弹框显示
const dialogVisibletitle = ref(false)//弹框标题
const store = useStore();
const state = reactive({
  interests: [],//权益
})
// 清除已认证企业
const check=()=>{
  dialogloading.value=true
  httpToken({
    method: "post",
    url: '/admin/sys-user/cleanUpEnterpriseInfo',
  }).then((res) => {
    handleMessage('已退出登录企业,切换为个人帐号!','success')
    switchClick()
    dialogVisible.value=false
  }).catch(()=>{
    dialogloading.value=false
  })
}
// 切换账号
const switchClick = function (el) {
  httpToken({
    method: "post",
    url: '/admin/sys-user/changingOver',
    data:qs.stringify({
      companyId:''
    })
  }).then(res => {
    //切换当前登录状态
    resetUserInfo(() => {//刷新个人信息
      window.sessionStorage.removeItem("buttonPermissions");//移除按钮权限
      getbutpermission().then(res=>{
        resetUserMenu()//重新调取菜单信息
      })//重新调取按钮权限信息
    })
  })
};
// 认证跳转
const approve = () => {
  let userInfo = JSON.parse(window.sessionStorage.user).user_info
  // type 1个人 2公司
  if (userInfo.sysCompany) {
    if (userInfo.isCertification == 0) {
      handleMessage('请先完成个人实名认证')
      return
    }
    store.commit('realName/resetData')
    store.commit('realName/setType', 2)
    store.commit('realName/setCompanyId', userInfo.sysCompany.companyId)
    store.commit('realName/setToCompanyName', userInfo.sysCompany.companyName)
    router_push_name('realname_boot')
  } else {
    store.commit('realName/resetData')
    store.commit('realName/setType', 1)
    router_push_name('realname_boot')
  }
}
const show = () => {
  let userInfo = JSON.parse(window.sessionStorage.user).user_info
  if (userInfo.sysCompany && userInfo.sysCompany.isauthentication != 1) {
    // 查询当前登录人所在企业是否被认证
    httpToken({
      method: "post",
      url: '/admin/sys-user/checkEnterpriseInfo',
    }).then((res) => {
      if (res.data) {
        dialogVisibletitle.value = '当前企业已被认证'
        dialogVisible.value = true
      } else {
        dialogVisibletitle.value = '当前企业未认证'
        state.interests = ['启用电子印章，以企业身份签合同', '解锁更多企业权益']
        dialogVisible.value = true
      }
      console.log('查询当前登录人所在企业是否被认证', res)
    })
  } else if (!userInfo.sysCompany && userInfo.isCertification != 1) {
    dialogVisibletitle.value = '当前个人未认证'
    state.interests = ['启用电子印章，以个人身份签合同', '解锁更多个人权益']
    dialogVisible.value = true
  } 
}
defineExpose({
  show,
});
</script>
<style lang="scss" scoped >
// @import "@/styles/general/element.scss";  //element 样式重置
@import '@/styles/color/value.scss';

.warning {
  width: 100%;
  background: $active-alpha;
  border-radius: 8px;
  padding-left: 27px;
  padding-top: 14px;
  padding-bottom: 14px;

  .icon {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #999999;
  }

  .interestItem+.interestItem {
    margin-top: 14px;
  }

  font-size: 12px;
  font-family: PingFangSC-Regular,
  PingFang SC;
  font-weight: 400;
  color: #666666;

  .title {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }
}</style>