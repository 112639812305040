import {handleMessage} from "@/utils/server/confirm";

//ArrayBuffer转base64
export function translateArrayBufferToBase64(buffer){
  return "data:image/jpeg;base64," +window.btoa(String.fromCharCode(...new Uint8Array(buffer)));
}

//base64转ArrayBuffer
export function translateBase64ToArrayBuffer(base64){
  const binaryStr = window.atob(base64);
  // console.log('binaryStr',binaryStr)
  const byteLength = binaryStr.length;
  // console.log('byteLength',byteLength)
  const bytes = new Uint8Array(byteLength);
  // console.log('bytes',bytes)
  for(let i=0;i<byteLength;i++){
      bytes[i] = binaryStr.charCodeAt(i);
  }
  return bytes.buffer;
}
// 根据arraybuffer流下载文件
export function download_file(resdata,fileName){
  let blob = new Blob([resdata]);
  let downloadElement = document.createElement("a");
  let href = window.URL.createObjectURL(blob); //创建下载的链接
  downloadElement.href = href;
  downloadElement.download = fileName //下载后文件名
  document.body.appendChild(downloadElement);
  downloadElement.click(); //点击下载
  document.body.removeChild(downloadElement); //下载完成移除元素
  window.URL.revokeObjectURL(href); //释放掉blob对象
}

// 根据element上传组件ref获取文件列表
export function getUpload(uploadref){
  let fileList = []
  let uploadFiles = uploadref.uploadFiles
  uploadFiles.forEach((item) => {
    if(item.raw){
      fileList.push(item.raw)
    }
  })
  return fileList;
}
// 导出Excel
// columns [{title: "所属部门", key: "deptName"},
// {title: "保密程度", key: "secrecyLevel",reset:{1:'高级',2:'中级',3:'低级'}},]
export function export2Excel(columns, list, fileName) {
  require.ensure([], () => {
    const {export_json_to_excel} = require('../excel/Export2Excel');
    let tHeader = []
    let filterVal = []
    // console.log(columns)
    if (!columns) { return; }
    columns.forEach(item => {
      tHeader.push(item.title)
      if(item.reset){
        filterVal.push({key:item.key,reset:item.reset})
      }else{
        filterVal.push({key:item.key})
      }
    })
    const data = list.map(v => filterVal.map(j => {
      if(j.reset){
        let resetkey=v[j.key]
        let data=j.reset[resetkey]
        if(!data)data='信息暂无'
        return data
      }else{
        return v[j.key]
      }
    }))
    export_json_to_excel(tHeader, data, fileName);
  })
}
// 获取文件类型名称
export function getfileTypeName(fileName){
  return fileName.substring(fileName.lastIndexOf('.') + 1)
}
// 图片按比例压缩 文件  回调函数 图片质量
export function compress(fileObj, callback,quality=0.1) {
  try {
    const image = new Image()
    image.src = URL.createObjectURL(fileObj)
    image.onload = function() {
      const that = this
      // 默认按比例压缩
      let w = that.width
      let h = that.height
      const scale = w / h
      w = fileObj.width || w
      h = fileObj.height || (w / scale)
      // 生成canvas
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      // 创建属性节点
      const anw = document.createAttribute('width')
      anw.nodeValue = w
      const anh = document.createAttribute('height')
      anh.nodeValue = h
      canvas.setAttributeNode(anw)
      canvas.setAttributeNode(anh)
      ctx.drawImage(that, 0, 0, w, h)
      // quality值越小，所绘制出的图像越模糊
      const data = canvas.toDataURL('image/jpeg', quality)
      // 压缩完成执行回调
      const newFile = convertBase64UrlToBlob(data)
      callback(newFile)
    }
  } catch (e) {
    Message({
      message: '压缩失败!',
      type: 'error',
      duration: 5 * 1000
    })
    console.log('压缩失败!')
  }
}
function convertBase64UrlToBlob(urlData) {
  const bytes = window.atob(urlData.split(',')[1]) // 去掉url的头，并转换为byte
  // 处理异常,将ascii码小于0的转换为大于0
  const ab = new ArrayBuffer(bytes.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i)
  }
  return new Blob([ab], { type: 'image/png' })
}