<template>
    <div class="tablebox autobox pl-12 pr-12 pb-12">
      <slot></slot>
    </div>
</template>

<style lang="scss" scoped >
@import '@/styles/color/value.scss';
.tablebox{
  box-sizing: border-box;
  width: 100%;
  min-width: 1250px;
  height: calc(100vh - 88px - 48px);
  // min-height: calc(100vh - 88px - 48px);
  background: $bg-transition;
}
</style>