import router from "../../router";
import store from "../../store"
// 根据名称路由跳转
export function router_push_name(name, params = {},isback=true,isSession=false) {
  // 缓存当前路由名称 用于跳转上一页
  if(isback){
    store.state.backRouteName = router.currentRoute.value.name
  }
  // session记录当前页 防止刷新
  if(isSession){
    sessionStorage.RouteInfo=name
    sessionStorage.RouteParams=JSON.stringify(params) 
  }
  router.push({
    name: name,
    params: params
  })
  // 本地测试使用
  // sessionStorage.RouteInfo=name
}
// 返回跳转
export function router_back(params = undefined,isback=undefined,isSession=undefined) {
  // console.log('store.state.backRouteName',store.state.backRouteName)
  // if(store.state.backRouteName)
  router_push_name(store.state.backRouteName,params,isback,isSession)
  // else
    // router.go(-1)
}