const module = {
  namespaced: true,//带命名空间 namespaced: true 的模块，所有功能区分模块，更高封装度和复用
  state: {
    currentPage: 1,//当前页 从1开始
    currentPageTem:1,
    basefilesIndex:0,//当前展示文件下标
    // 当前签署人所有指定签署位置
    allSignaturePositionDatas: [], //签署位置的原始数据
    sealpositionDatas:[],//签章
    signaturepositionDatas:[],//签名
    datepositionDatas:[],//日期
    signsDatas:[],//签名缓存数据
    checkTime:'',//检测是否可签署时间
  },
  mutations: {
    resetData(state){
      state.currentPage=1
      state.basefilesIndex=0
      state.sealpositionDatas=[]
      state.signaturepositionDatas=[]
      state.datepositionDatas=[]
      state.signsDatas=[]
    },
    resetpositionData(state){
      state.sealpositionDatas=[]
      state.signaturepositionDatas=[]
      state.datepositionDatas=[]
    },
    setcheckTime(state,PageIndex){
      state.checkTime=PageIndex
    },
    setcurrentPage(state,PageIndex){
      state.currentPage=PageIndex
    },
    //模版文件下标
    setcurrentPageTem(state,PageIndex){
      state.currentPageTem=PageIndex
    },
    setbaseFilesIndex(state,index){
      state.basefilesIndex=index
    },
    setpositionDatas(state,data){
      state.allSignaturePositionDatas = data
      data.forEach(item => {
        if(item.sealPositions){
          item.sealPositions.forEach(item2=>{
            item2.fileId=item.fileId
            item2.sealType=item.signingRequirements
          })
          if(item.type==0){
            state.sealpositionDatas=[...state.sealpositionDatas,...item.sealPositions]
          }else if(item.type==1){
            state.signaturepositionDatas=[...state.signaturepositionDatas,...item.sealPositions]
          }else if(item.type==2){
            state.datepositionDatas=[...state.datepositionDatas,...item.sealPositions]
          }
        }
      });
    },
    setsignsDatas(state,data){
      state.signsDatas=data
    }
  },
  actions: {}
}

export default module