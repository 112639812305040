import { handleMessage } from '@/utils/server/confirm'
import { loginOut } from "@/api/login"
export function setMsgbox(res,type=1) {
    // console.log("res: ", res)
    if (res.statusCode >= 400 && res.statusCode < 500 ) {
        handleMessage("登录已过期，请重新登陆")
        return Promise.reject(new Error('登录过期'))
    }

    let { data } = res
    // 返回的数据 按各系统之前信息发回 如OA系统改成返回了res.data
    let returnData=res
    if(type==1)
        returnData=res.data
    if (data.code == 200) {//正确
        return returnData
    } else if (data.code == 201) {//'流水号重复'
        if(data.msg)
            handleMessage(data.msg)
        return returnData
    } else if (data.code == 300) {//'登录信息错误'
        // 如有添加各系统自己退出登录方法
        // router.go(0);
        // window.sessionStorage.removeItem("token");
        // window.sessionStorage.removeItem("user");
        // window.sessionStorage.removeItem("roles");
        // window.sessionStorage.removeItem("MENU");
        // router.replace({ path: "/login" })
        return Promise.reject(new Error('登录过期'))
    } else if(data.code == 401){//无权限
        // 如有添加各系统自己重新掉取权限方法
        if(data.msg)
            handleMessage(data.msg||'无权限')
        return returnData
    }else if(data.code == 424){//请求令牌过期
        loginOut()
    } else if(data.code == 500){//其他问题 无提示
        if(data.msg)
            handleMessage(data.msg)
        console.log('code500错误提示',res)
        return Promise.reject(new Error('服务器问题code500'))
    }else{//其他问题 有提示
        if(data.msg)
            handleMessage(data.msg)
        return Promise.reject(new Error('其他问题'))
    }
}