const module = {
  namespaced: true,//带命名空间 namespaced: true 的模块，所有功能区分模块，更高封装度和复用
  state: {
    individualCenterIndex: 0,//个人中心当前选中下标
  },
  mutations: {
    //增查改状态
    setindividualCenterIndex(state, str) {
      state.individualCenterIndex = str
    },
  },
  actions: {}
}

export default module