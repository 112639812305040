<template>
  <div class="searchbox">
    <slot></slot>
    <div class="mr-at">
      <slot name="mr"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped >
.searchbox{
  width: 100%;
  // height: 36px;
  // padding: 0 2%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
</style>