<template>
  <div class="h-40 bg-fff dp-f ai-c title">
    消息通知
  </div>
  <div class="bg-fff page pb-10" v-loading="loading" :element-loading-text="config.loading.text"
    :element-loading-spinner="config.loading.spinner" :element-loading-background="config.loading.background">
    <div class="mt-8 dp-f jc-sb" v-for="(item, index) in state.Notice" :key="index">
      <div class="dp-f ai-c">
        <div class="w-6 h-6 br50 bg-f00 mr-10 fs-0" v-if="item.status == 0"></div>
        <div v-html="item.messageContent" @click="getMessage(item)" :class="[(item.workType=='发起合同'||item.workType=='签署合同')?'cu-p':'',!item.status == 0 ? 'w-300' : 'w-284']" class="t-oh"></div>
      </div>
      <div class="w-100 ta-r c-999 fs-0 t-oh">{{ item.messageTime }}</div>
    </div>
  </div>
  <div class="more ta-c cu-p mt-20 mb-6" @click="router_push_name('message', undefined, undefined, true)">
    查看所有消息通知
  </div>
</template>
 
<script setup>
import { ref, unref, reactive, onUnmounted } from 'vue'
import { httpToken } from "@/utils/request";
import { router_push_name } from "@/utils/server/router.js"
import { useStore } from "vuex";
import qs from "qs";
const store = useStore();
const loading = ref(false);//loading 显示
const state = reactive({
  Notice: [],
  setIntervalobj: {},//定时器对象
})
const getMessage = (el) => {
  if (el.workType == '发起合同') {
    router_push_name('sign_details', { id: el.workTypeId }, undefined, true)
  }else if(el.workType=='签署合同'){
    httpToken({
      method: "post",
      url: '/initiate-contract/contract/list',
      data:qs.stringify({
        paramData:JSON.stringify([
        { column: 'id', type: 'eq', value: el.workTypeId }
        ])
      })
    }).then((res) => {
      res.data.records.forEach(item => {
        item.showSignatory = ''
        item.contractSignRecords.forEach((item2, ind2) => {
          item.contractTypeStr = item.contractTypeStr ? item.contractTypeStr : '/'
          item.initiatorStr = item.initiatorStr ? item.initiatorStr : '/'
          item.showSignatory += ind2 == 0 ? '' : ','
          item.showSignatory += item2.signerType == 1 ? item2.contractSignPersonList[0].companyName : item2.contractSignPersonList[0].signerName
        })
      });
      store.state.messageData=res.data.records[0]
      store.state.messageIndex++
    })
  }
}
const getDataAll = () => {
  state.Notice = []
  loading.value = true
  httpToken({
    method: "post",
    url: '/admin/messageuser/list',
  }).then((res) => {
    res.data.records.forEach((item, ind) => {
      item.messageContent = item.messageContent.replace(/\${name}/g, `<span style="color:rgba(40, 144, 255, 1);">《${item.contractName}》</span>`)
    })
    state.Notice = res.data.records
    loading.value = false;
  }).catch((err) => {
    loading.value = false;
  })
}
getDataAll()
// 5分钟重新调取消息
state.setIntervalobj = setInterval(() => {
  getDataAll()
}, 300000)
onUnmounted(() => {
  window.clearInterval(state.setIntervalobj);
})
</script>
<style lang="scss" scoped>
.more {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}

.title {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}

.page {
  overflow: auto;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;

  span {
    cursor: pointer;
    color: rgba(40, 144, 255, 1);
  }

  .fs-0 {
    flex-shrink: 0;
  }
}
</style>