const module = {
  namespaced: true,//带命名空间 namespaced: true 的模块，所有功能区分模块，更高封装度和复用
  state: {
    type: '',//实名类型 1个人 2企业
    companyId:'',//当前认证企业id
    recordId:'',//当前认证企业执行中认证记录id
    companyInfo:null,//当前认证企业信息
    tocompanyName:'',//去认证公司名称
  },
  mutations: {
    resetData(state){
      state.type=''
      state.companyId=''
      state.recordId=''
      state.companyInfo=null
    },
    // 设置企业id
    setCompanyId(state,id){
      state.companyId = id
    },
    // 设置当前认证企业执行中认证记录id
    setRecordId(state,id){
      state.recordId = id
    },
    // 设置企业信息
    setCompanyInfo(state,data){
      state.companyInfo = data
    },
    //增查改状态
    setType(state, str) {
      state.type = str
    },
    // 设置去认证公司名称
    setToCompanyName(state,name){
      state.tocompanyName = name
    },
  },
  actions: {}
}

export default module