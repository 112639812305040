<template>
	<el-drawer custom-class="drawerIconNone" v-model="drawerVisible" :close-on-click-modal="false" title="合同详情"
		direction="rtl" size="500px">
		<div class="detailsbox">
			<div class="ovbox">
				<!-- {{ state.baseInfo.contractStatus }} -->
				<div class="labelbox dp-f">
					<div class="label">合同类型:</div>{{ state.baseInfo.contractTypeStr }}
				</div>
				<div class="labelbox dp-f">
					<div class="label">合同标题:</div>{{ state.baseInfo.contractName }}
				</div>
				<div class="labelbox dp-f">
					<div class="label">状态:</div>
					<div :class="['status', 'status' + state.baseInfo.contractStatus]">{{
						getcontractStatus(state.baseInfo.contractStatus) }}</div>
				</div>
				<div class="labelbox dp-f">
					<div class="label">签署截止日期:</div>{{ state.baseInfo.contractDeadline }}
				</div>
				<div class="labelbox dp-f">
					<div class="label">合同发送日期:</div>{{ state.baseInfo.initiateData }}
				</div>
				<div class="labelbox dp-f">
					<div class="label">发起方:</div>
					<template v-if="state.baseInfo.initiatorStr&&state.baseInfo.initiatorStr!='/'">
						{{ state.baseInfo.initiatorStr }}&
					</template>
					{{ state.baseInfo.sponsorStr }}
				</div>
				<div class="dp-f jc-sb ai-fe mt-50 mb-18">
					<div class="fs-18">合同签署</div>
					<div class="fs-14 c-999">{{ getSignOrder(state.baseInfo.signOrder) }}</div>
				</div>
				<items :items="state.baseInfo.contractSignRecords"></items>
			</div>
			<!-- return code == 0?'待我处理':code == 1?'签署中':code == 2?'已完成':code == 3?'已过期':code == 4?'草稿箱':code == 5?'抄送':'未知' -->
			<div class="fotbox dp-f jc-fe ai-c">
				<oabutton v-if="state.withdrawIs" class="searcML" width='100' height='40' title="撤回" CSStype=3
					@buttonclick="clickUserCheck(()=>{withdraw(state.baseInfo.id)})" />
				<oabutton class="searcML" width='100' height='40' title="取消" CSStype=3 @buttonclick="drawerVisible = false" />
				<!-- 实名后才有签署按钮 -->
				<oabutton v-if="state.baseInfo.contractStatus == 0" class="searcML mr-12" width='100' height='40' title="签署"
					CSStype=2 @buttonclick="clickUserCheck(()=>{signClick(1)})" v-model:loading="dialogloading" />
				<oabutton v-else class="searcML mr-12" width='100' height='40' title="查看合同" CSStype=2
					@buttonclick="signClick()" />
			</div>
		</div>
	</el-drawer>
</template>
<script setup>
import { reactive, ref, defineEmits, computed } from 'vue'
import { getcontractStatus, getSignOrder } from "@/utils/server/getcode.js"
import { router_push_name } from "@/utils/server/router.js"
import { handleCofirm, handleMessage } from "@/utils/server/confirm";
import {clickUserCheck} from "@/utils/base/realname.js"
import { httpToken } from "@/utils/request";
import qs from "qs";
import items from "./items.vue"
const dialogloading = ref(false);// 弹框按钮loading 显示
const emit = defineEmits(['withdrawEmit'])
const drawerVisible = ref(false)//抽屉显示
const state = reactive({
	selectionCount: 0,//选中数量
	baseInfo: {},
	withdrawIs: false,//是否可撤回
})
const userInfo = computed(() => {
	return JSON.parse(window.sessionStorage.user).user_info
})
// 撤回点击
const withdraw = (id) => {
	httpToken({
		method: "post",
		url: '/initiate-contract/contract/recallContract',
		data: qs.stringify({
			id: id
		}),
	}).then((res) => {
		emit('withdrawEmit')
		drawerVisible.value = false
	})
}
const getDetails = ((data) => {
	state.baseInfo = data
	drawerVisible.value = true
})
// isAuthentication 企业实名认证 0是未认证 1是已认证
// isCertification 个人实名认证状态 0是未认证 1是已认证
// 0默认 1签署
const signClick = ((type=0) => {
	// if (type == 1) {
	// 	if (userInfo.isCertification != 1) {
	// 		handleMessage('请先完成个人实名认证')
	// 		return
	// 	}
	// 	if (userInfo.isAuthentication != 1) {
	// 		handleMessage('请先完成企业实名')
	// 		return
	// 	}
	// }
	router_push_name('sign_details', { id: state.baseInfo.id,type:type }, undefined, true)
})
defineExpose({
	getDetails
});
</script>
<style lang="scss" scoped >
@import "@/styles/general/layout/labelbox.scss";

.detailsbox {
	width: 100%;
	height: 100%;
	position: relative;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #333333;
	padding: 0 34px;
	padding-top: 34px;

	.ovbox {
		height: calc(100% - 60px);
		overflow: auto;
	}

	.labelbox {
		.status {
			color: #0083FF;
		}

		.status3,
		.status4 {
			color: #999999;
		}
	}

	.fotbox {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 60px;
		background: #FFFFFF;
		box-shadow: 0px -1px 8px 0px rgba(219, 219, 219, 0.5);
	}

	// 隐藏滚动条
	::-webkit-scrollbar {
		width: 0 !important
	}
}
</style>