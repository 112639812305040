<template>
  <div :class="{ navCollapsed: state.isSidebarNavCollapse }">
    <NAVTOP ref="topRef"></NAVTOP>
    <NAVLEFT class="NAVLEFT"></NAVLEFT>
    <certification ref="certificationRef" />
    <updateEntInfo ref="updateEntInfoRef" />
    <Detailsdrawer ref="detailsRef" @withdrawEmit=""></Detailsdrawer>
  </div>
</template>
<script setup>
import { reactive,watch, computed, onMounted,nextTick, ref, unref } from 'vue';
import NAVTOP from "./component/navTop/navTop.vue";
import NAVLEFT from "./component/navLeft/navLeft.vue";
import certification from "@/components/dialog/certification.vue"
import updateEntInfo from "@/components/dialog/updateEntInfo.vue"
import { useStore } from "vuex";
import Detailsdrawer from "@/views/contractDocuments/components/details.vue"//详情
// import {configu} from '@/utils/config'
const store = useStore();
const state = reactive({
  isSidebarNavCollapse: computed(() => store.getters.getIsSidebarNavCollapse),
});
const detailsRef = ref();//详情弹框ref
const certificationRef = ref()
const updateEntInfoRef= ref()
const topRef = ref(null);//告警信息弹窗ref
watch(() => store.state.messageIndex, (value, oldValue) => {
  if(value){
    console.log('value',value)
    unref(detailsRef).getDetails(store.state.messageData)
  }
})
onMounted(()=>{
  console.log("nva.vue")
  nextTick(()=>{
    unref(certificationRef).show()
    unref(updateEntInfoRef).show()
  })
})
</script>
<style lang="scss" scoped >
.NAVLEFT {
  height: calc(100vh - 88px);
}
</style>
