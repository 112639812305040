import { httpToken } from "@/utils/request";
import store from "@/store"
// import qs from 'qs'
// 重置用户信息
export const resetUserInfo = (callback=()=>{},errback=()=>{}) => {
  return httpToken({
    url: '/admin/sys-user/info',
    method: 'post',
  }).then(res=>{
    // console.log('重置用户信息',res)
    let sysUserAllData = JSON.parse(window.sessionStorage.user)
    sysUserAllData.user_info=res.data
    window.sessionStorage.setItem("user",JSON.stringify(sysUserAllData));//用户信息
    callback()
  }).catch(()=>{
    errback()
  })
}
// 重置用户菜单路由
export const resetUserMenu = (callback=()=>{}) => {
  // console.log('重置用户菜单路由执行')
  window.sessionStorage.removeItem("MENU");
  window.sessionStorage.removeItem("MENU2");
  return store.dispatch("FETCH_PERMISSION").then(() => {
    callback()
  });
}
// 注销账号
export const accountCancellation = () => {
  return  httpToken({
    method: "post",
    url: '/admin/sys-user/accountCancellation',
  }).then(res => {
    router.go(0);
    window.sessionStorage.removeItem("token");
    window.sessionStorage.removeItem("user");
    window.sessionStorage.removeItem("RouteInfo");
    window.sessionStorage.removeItem("MENU");
    window.sessionStorage.removeItem("MENU2");
    window.sessionStorage.removeItem("buttonPermissions");
    router.replace({ path: "/login" });
  })
}
