const module = {
  namespaced: true,//带命名空间 namespaced: true 的模块，所有功能区分模块，更高封装度和复用
  state: {
    typeIndex: 0,//当前选中主题下标
  },
  mutations: {
    setTypeIndex(state, str) {
      state.typeIndex = str
    },
  },
  actions: {}
}

export default module