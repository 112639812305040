import { httpToken } from "@/utils/request";
import store from "../../store"
import qs from "qs";
// 根据公司id获取当前执行中的认证记录
export function getrecordId() {
    if(store.state.realName.companyId){
        httpToken({
            method: "post",
            url: '/admin/certification/selectById',
            data: qs.stringify({
                'companyId': store.state.realName.companyId
            })
        }).then((res) => {
            // console.log('查询当前执行中的认证记录', res.data)
            if(res.data){
                store.commit('realName/setRecordId', res.data.id)
                selectById(res.data.id,2)
            }
        })
    }

}
// 根据认证id查详情数据
export function selectById(id,type=1) {
    return httpToken({
        method: "post",
        url: '/admin/certification/selectByAuthenticationId',
        data: qs.stringify({
            'authenticationId': id
        })
    }).then((res) => {
        if(type==2){
            store.commit('realName/setCompanyInfo', res.data)
        }
        console.log('查询当前企业认证信息2', store.state.realName.companyInfo)
        return res.data
    })
}