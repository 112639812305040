import { reactive, ref, onMounted, computed } from "vue";
// 姓名脱敏处理 隐藏姓名中的第一个字，如为英文等其他语种，也是隐藏第一个字母。如：*大友、*安、*ahn
export function chnameDesensitization(name, defaultStr = '暂未实名') {
  return computed(() => {
    if (name) {
      if (name.length > 1) {
        return '*' + name.substring(1, name.length)
      } else {
        return name
      }
    } else {
      return defaultStr
    }
  })
}
// 手机号脱敏处理  显示前 3 位+****+后 4 位。如：137****9050
export function phoneDesensitization(phone, defaultStr = '暂无手机号') {
  return computed(() => {
    if (phone) {
      return phone.replace(/^(.{3})(?:\d+)(.{4})$/, "$1****$2")
    } else {
      return defaultStr
    }
  })
}
// 身份证脱敏处理  显示前 1 位+****+后 1 位。如：1****0
export function idcardDesensitization(idcard, defaultStr = '暂无身份证') {
  return computed(() => {
    if (idcard) {
      return idcard.replace(/(\w{1})\w*(\w{1})/, '$1******$2')
    } else {
      return defaultStr
    }
  })
}