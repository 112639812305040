import { filehttp_token,filehttpToken } from "@/utils/request";
import { download_file,translateBase64ToArrayBuffer } from "@/utils/server/file"
import { debounce } from "@/utils/util.js";
import { handleMessage } from "@/utils/server/confirm";
import qs from "qs";
// 单文件上传
export function uploadFile(file, moduleType = 'null', formId = '999', toPdf = false, url = '/sys-file/upload') {
  const formData = new FormData();
  formData.append('file', file)
  formData.append('formId', formId)
  formData.append("moduleType", moduleType);
  formData.append("toPdf", toPdf);
  return filehttpToken({
    url: url,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData
  }).then((res) => {
    // console.log('单文件上传res',res)
    return res.data
  }).catch((error) => {
    // console.log('单文件上传error',error)
    return new error(error)
  });
}
// 文件转pdf
export function fileByPdf(type, files) {
  const formData = new FormData();
  files.forEach((item) => {
    formData.append('file', item)
  })
  formData.append("type", type);
  return filehttpToken({
    url: "/sys-file/fileByPdf",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData
  }).then((res) => {
    return res.data
  }).catch((error) => {
    // handleMessage('文件转pdf失败')
    return new error(error)
  });
}
// 获取文件pdf转图片列表
export function getpdfByPic(fileName, url) {
  const formData = new FormData();
  formData.append('fileName', fileName)
  formData.append("url", url);
  // 根据返回的pdf地址调取转图片方法 取出图片列表
  return filehttpToken({
    url: "/sys-file/pdfByPic",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData
  }).then((res) => {
    return res.data
    // console.log("pdf转图片方法", res);
  }).catch((error) => {
    // handleMessage('pdf转换失败')
    return new error(error)
  });
}
// 文件验签
export function verifySignatures(file) {
  const formData = new FormData();
  formData.append('file', file)
  return filehttpToken({
    url: '/sys-file/verifySignatures',
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData
  }).then((res) => {
    return res.data
  }).catch((error) => {
    return new error(error)
  });
}
 function downloadFile(res, fileName) {
  var blob = new Blob([res.data])
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  reader.onload = (e) => {
    const a = document.createElement('a')
    a.download = `${fileName}.zip`
    a.href = e.target.result
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}
// 批量下载合同
export const batchDownloadFile = debounce((ids,url='/sys-file/sys-file/batchDownloadFile')=> {
  handleMessage('开始下载合同','success')
  filehttp_token({
    url: url,
    method: "post",
    data:ids,
    responseType: 'blob',
  }).then((res) => {
    // window.open(res.data.msg+"?attname=批量下载合同.zip")
    downloadFile(res,'批量下载合同')
  })
}, 200)
// 批量下载合同
export const downloadFileByContract = debounce((id,url='/sys-file/downloadFileByContract')=> {
  filehttpToken({
    url: url,
    method: "post",
    data: qs.stringify({
      form_id : id,
      module_type : 'templateCon'
    })
  }).then((res) => {
    if (res.data.code == 200) {
      // window.open(res.data.data.fileUrl+"?attname=批量模板字段导入.xlsx")
      window.open(res.data.data.fileUrl+"?attname="+res.data.data.fileChname)
      // // 循环返回的文件执行下载
      // res.data.data.forEach(item => {
      //   download_file(translateBase64ToArrayBuffer(item.data), item.fileName+'.xlsx')
      // })
    }
  })
}, 200)