<template>
  <div class="indicate w100 h-48 dp-f ai-c">
      <div v-for="(el,ind) in route.matched" :key="ind">
        <span v-if="ind==0"></span>
        <span v-else-if="ind != route.matched.length-1">
          <span class="mr-6">{{el.meta.name}}</span>
          <span class="mr-6">/</span>
        </span>
        <template v-else>
          <!-- 写入的自定义面包屑 -->
          <template v-if="route.meta.superiors">
            <span v-for="(el2,ind2) in route.meta.superiors" :key="ind2">
              <span class="mr-6">{{el2}}</span>
              <span class="mr-6">/</span>
            </span>
          </template>
          <span class="c-333">{{el.meta.name}}</span>
        </template>
      </div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
const route=useRoute()
</script>

<style lang="scss" scoped>
@import '@/styles/color/value.scss';
.indicate {
  padding:0 12px;
  background: $bg-transition;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
</style>