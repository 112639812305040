<template>
  <el-upload ref="uploadRef" class="upload-demo" :class="'uploadDemo' + props.cssType" drag action="#" :limit="1"
    :accept='import_img_accept' :http-request="ImgHttpImport" list-type="picture-card" multiple>
    <template v-if="props.cssType == 1">
      <div class="dragbox dp-fc ac-c fw-w" v-show="!state.nowFile">
        <img src="@/assets/img/realname/license.png" class="w-104 mb-18" />
        <div class="dp-f jc-c">
          请将文件拖拽至此，或<img src="@/assets/img/general/upload.png" class="w-16 h-16 ml-6 mr-3 mt-2 cu-p" /><span
            class="cu-p">点击上传</span>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="Idcardbox" v-show="!state.nowFile">
        <div class="transitionLine">
          <div class="addbox pt-r m-0-a h-30 mb-24 mt-30">
            <div class="line1 pt-a"></div>
            <div class="line2 pt-a"></div>
          </div>
          点击上传 {{props.cssType==2?'身份证正面':'身份证反面'}}
        </div>
      </div>
    </template>
    <template #file="{ file }">
      <div class="dragbox dp-fc imgdragbox"  v-show="state.nowFile">
        <img class="fileImg" :src="file.url" alt="" />
        <div class="delbox dp-fc" @click="resetFile()">
          <img class="w-28 h-28" src="@/assets/img/general/delete.png">
        </div>
      </div>
    </template>
  </el-upload>
</template>
<script setup>
import { reactive, ref, unref, nextTick } from "vue";
import { handleMessage } from "@/utils/server/confirm";
import { import_img_accept } from "@/utils/base/accept"
import { getfileTypeName } from "@/utils/server/file.js"
const props = defineProps({
  // 当前选择的下标
  cssType: {
    type: Number,
    default: 1
  },
  fileSize: {
    type: Number,
    default: 1
  }
});
const state = reactive({
  nowFile: false,
});
const uploadRef = ref()//上传组件ref
// 图片上传方法
const ImgHttpImport = ((rawFile) => {
  console.log('rawFile', rawFile, rawFile.file.size)
  let fileTypeName = getfileTypeName(rawFile.file.name)
  let imgtype = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG', 'bmp', 'BMP']
  console.log('fileTypeName', fileTypeName)
  if (rawFile.size / 1024 / 1024 > props.fileSize) {
    handleMessage('图片大小超过' + props.fileSize + 'MB')
    resetFile()
  } else if (imgtype.includes(fileTypeName)) {
    state.nowFile = rawFile
  } else {
    handleMessage('请上传正确图片格式!')
    resetFile()
  }
})
const resetFile = (() => {
  state.nowFile = false
  nextTick(() => {
    unref(uploadRef).clearFiles()
  })
})
const getFile = (() => {
  return state.nowFile
})
defineExpose({
  getFile
});
</script>
<style lang="scss" scoped >
::v-deep .el-upload-dragger {
  border: none !important;
  padding: 0 !important;
}

::v-deep .el-upload--picture-card {
  border: none !important;
}

::v-deep .el-upload--picture-card {
  --el-upload-picture-card-size: 100%;
}

::v-deep .el-upload-list--picture-card {
  --el-upload-list-picture-card-size: 100%;
  display: block;
}

.dragbox {
  background: linear-gradient(360deg, rgba(238, 199, 116, 0.05) 0%, rgba(238, 199, 116, 0.06) 100%);
  border-radius: 6px;
  border: 1px solid #CCCCCC;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;

  span {
    color: #1890FF;
  }

  .fileImg {
    max-width: calc(100% - 30px);
    max-height: calc(100% - 30px);
  }
}

// .upload-demo {
//   max-width: 100%;
//   max-height: 100%;
// }

.imgdragbox {
  // padding: 15px;
  position: relative;

  .delbox {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.4);
  }
}

.imgdragbox:hover {
  .delbox {
    display: flex;
  }
}

.Idcardbox {
  width: 234px;
  height: 146px;
  background: #FEFCF8;
  border-radius: 4px;
  padding: 6px;

  .transitionLine {
    width: 100%;
    height: 100%;
    border-radius: 2px;
    border: 1px dashed #D9DEE4;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    .addbox{
      div{
        background: #999999;
      }
      .line1{
        left: calc(50% - 14px) ;
        top: 14px;
        width: 30px;
        height: 2px;
      }
      .line2{
        left: 50%;
        width: 2px;
        height: 30px;
      }
    }
  }

  .transitionLine:hover {
    border-color: #1890FF;
  }
}
</style>