import { createStore } from "vuex";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
import Module from './module/Module'
import realName from './module/realName'
import route from './module/route'
import sign from './module/sign'
import initiate from './module/initiate'
import theme from './module/theme'
export default createStore({
  state: {
    Mouseis: false,//鼠标移入
    Mouseleft: 0,
    Mousetop: 0,
    MouseType:0,//鼠标移入类型
    MouseData: {},//鼠标移入数据
    MousepositionData: {
      data: {},
      index: 0,
      parentH:0,
      parentW:0
    },//鼠标移入位置数据
    MouseIndex: 0,//鼠标移动次数
    contractId: false,
    buttonPermissions: [],//当前所有按钮权限
    backRouteName: '',//返回路由的名称
    sidebarMenu: [],//控制台菜单
    sidebarMenu2: [],//工作台菜单
    currentMenu: "" /** 当前active导航菜单 */,
    /* 导航菜单是否折叠 */
    isSidebarNavCollapse: false,
    /* 面包屑导航列表 */
    crumbList: [],
    mysiginleng: null,
    getTemlist: [],//模版数据
    messageData:{},//邮件信息
    messageIndex:0,
  },
  mutations,
  actions,
  getters,
  modules: {
    Module,
    realName,
    route,
    sign,
    initiate,
    theme,
  }
});
