import { da } from "element-plus/es/locale"

const mutations={
    SET_BUTTONPERMISSIONS(state, data) {
      state.buttonPermissions = data
    },
    //左边导航栏列表
    SET_MENU(state, menu) {
        state.sidebarMenu = menu
    },
    SET_LENGTH(state,data){
        state.mysiginleng = data
    },
    //左边导航栏列表
    SET_MENU2(state, menu) {
        state.sidebarMenu2 = menu
    },
    //当前选中的导航
    SET_CURRENT_MENU(state, currentMenu) {
        state.currentMenu = currentMenu
    },
    //导航栏是否折叠
    toggleNavCollapse(state) {
        state.isSidebarNavCollapse = !state.isSidebarNavCollapse
    },
    /* 面包屑导航列表 */
    setCrumbList(state, list) {
        state.crumbList = list
    },
    pushgetTemlist(state,data){
        state.getTemlist.push(data)
    },
    setgetTemlist(state,data){
        state.getTemlist=data
    }

}
export default mutations