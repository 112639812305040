<template>
  <router-view />
</template>

<script>
import { defineComponent, onMounted, ref, unref, watch } from "vue";
import router from "@/router/index";
import { useStore } from "vuex";
import { router_push_name } from "@/utils/server/router.js"
import { setTheme } from "@/utils/base/theme"
import { setwatermark } from "@/utils/server/setwatermark.js"
import "@/assets/fontimg/iconfont.js"
export default defineComponent({
  setup(props) {
    const store = useStore();
    onMounted(() => {
      setTheme()
      // setwatermark()
      if (window.sessionStorage.MENU) {
        store.dispatch("FETCH_PERMISSION").then(() => {
          store.commit("SET_BUTTONPERMISSIONS", window.sessionStorage.buttonPermissions?JSON.parse(window.sessionStorage.buttonPermissions):[]);
          if (sessionStorage.RouteInfo) {
            router_push_name(sessionStorage.RouteInfo, JSON.parse(sessionStorage.RouteParams), false, true)
          } else {
            router.push({ path: "/" });
          }
        });
      }
    });
    return {

    }
  },
});
</script>

<style lang="scss">
@import "assets/fontimg/iconfont.css";

* {
  margin: 0;
}
</style>
