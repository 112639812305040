import store from "@/store/index";
// 修改全局颜色方法
export function setTheme() {
  if (window.sessionStorage.user) {
    if (JSON.parse(window.sessionStorage.user).user_info.sysCompany) {
      if (JSON.parse(window.sessionStorage.user).user_info.sysCompany.color) {
        execute(JSON.parse(window.sessionStorage.user).user_info.sysCompany.color)
      } else {
        execute()
      }
    } else {
      execute()
    }
  } else {
    execute()
  }
}
// 修改全局颜色方法
export function setTheme2(colorIndex) {
  execute(colorIndex)
}
function execute(typeIndex) {
  store.commit('theme/setTypeIndex', typeIndex)
  if (typeIndex == 1) {
    // 蓝色主题
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme', 'rgba(0, 115, 255, 1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme2', '#fff');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme3', 'rgba(0, 115, 255, 1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme4', 'rgba(255, 255, 255, 0.1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-alpha', 'rgba(0, 115, 255, 0.1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--color-button', '#fff');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-ming', 'rgba(0, 115, 255, 1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-alpha-color', 'rgba(255, 255, 255, 0.2)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-active-theme', 'rgba(135, 201, 255, 1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-font-color', '#fff');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-font-color-alpha', 'rgba(255, 255, 255, 0.2)');
  } else if (typeIndex == 2) {
    // 红色主题
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme', 'rgba(234, 68, 58, 1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme2', '#fff');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme3', 'rgba(234, 68, 58, 1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme4', 'rgba(255, 255, 255, 0.1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-alpha', 'rgba(234, 68, 58, 0.1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--color-button', '#fff');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-ming', 'rgba(234, 68, 58, 1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-alpha-color', 'rgba(255, 255, 255, 0.2)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-active-theme', 'rgba(135, 201, 255, 1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-font-color', '#fff');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-font-color-alpha', 'rgba(255, 255, 255, 0.2)');
  } else if (typeIndex == 3) {
    // 橙色主题
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme', 'rgba(253, 141, 63, 1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme2', '#fff');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme3', 'rgba(253, 141, 63, 1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme4', 'rgba(255, 255, 255, 0.1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-alpha', 'rgba(253, 141, 63, 0.1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--color-button', '#fff');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-ming', 'rgba(253, 141, 63, 1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-alpha-color', 'rgba(255, 255, 255, 0.2)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-active-theme', 'rgba(135, 201, 255, 1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-font-color', '#fff');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-font-color-alpha', 'rgba(255, 255, 255, 0.2)');
  } else if (typeIndex == 4) {
    // 默认黑色主题 2
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme', 'rgba(249, 219, 97, 1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme2', 'rgba(249, 219, 97, 1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme3', '#000201');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme4', '#000');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-alpha', 'rgba(249, 219, 97, 0.1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--color-button', '#000201');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-ming', '#000201');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-alpha-color', 'rgba(249, 219, 97, 0.1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-active-theme', 'rgba(135, 201, 255, 1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-font-color', '#fff');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-font-color-alpha', 'rgba(255, 255, 255, 0.2)');
  } else if (typeIndex == 5) {
    // 默认白色主题
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme', 'rgba(249, 219, 97, 1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme2', 'rgba(249, 219, 97, 1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme3', '#000201');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme4', '#fff');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-alpha', 'rgba(249, 219, 97, 0.1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--color-button', '#000201');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-ming', '#fff');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-alpha-color', 'rgba(249, 219, 97, 0.1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-active-theme', 'rgba(135, 201, 255, 1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-font-color', '#000201');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-font-color-alpha', 'rgba(0, 0, 0, 0.2)');
  } else {
    // 默认黑色主题
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme', 'rgba(238, 199, 116, 1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme2', 'rgba(238, 199, 116, 1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme3', '#292728');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme4', '#000');
    window.document.getElementsByTagName('body')[0].style.setProperty('--active-alpha', 'rgba(238, 199, 116, 0.1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--color-button', '#292728');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-ming', '#292728');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-alpha-color', 'rgba(238, 199, 116, 0.1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-active-theme', 'rgba(135, 201, 255, 1)');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-font-color', '#fff');
    window.document.getElementsByTagName('body')[0].style.setProperty('--bg-font-color-alpha', 'rgba(255, 255, 255, 0.2)');
  }
}
// $active-theme:var(--active-theme);//系统默认主题色
// $active-alpha:var(--active-alpha);//系统默认浅色主题色 0.2主题色
// $color-button:var(--color-button); //按钮默认字体色
// $bg-ming: var(--bg-ming);//系统背景颜色