const module = {
  namespaced: true,
  state: {
    ModuleState: '',//增查改状态
    ModuleId: '',//id
    ModuleName: '',//名称
    ModuleDId: '',//did
    ModuleType: '',//父级类型
    ModuleBackModule: '',//返回模块名称
    ModuleBackSwitch: '',//切换页面内的标签页
    ModuleBackData: '',//传输单条数据
  },
  mutations: {
    //增查改状态
    setModuleState(state, str) {
      state.ModuleState = str
    },
    //传输id
    setModuleId(state, str) {
      state.ModuleId = str
    },
    //传输名称
    setModuleName(state, str) {
      state.ModuleName = str
    },
    //传输父id
    setModuleDId(state, str) {
      state.ModuleDId = str
    },
    //父级类型
    setModuleType(state, str) {
      state.ModuleType = str
    },
    //返回模块名称
    setModuleBackModule(state, str) {
      state.ModuleBackModule = str
    },
    //切换页面内的标签页
    setModuleBackSwitch(state, str) {
      state.ModuleBackSwitch = str
    },
    //传输单条数据
    setModuleBackData(state, str) {
      state.ModuleBackData = str
    },
  },
  actions: {}
}

export default module
