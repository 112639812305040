<template>
    <div class="menu-container">
        <!-- <div class="slicesbox dp-fc" v-if="slicesShow===1">
            <img :class="['cu-p',state.isSidebarNavCollapse?'overturn':'']" src="@/assets/img/nav/slices.png" @click="toggleNavCollapse">
        </div> -->
        <template v-for="v in state.menuList" :key="v.name">
            <!-- 存在子集 -->
            <div v-if="v.children && v.children.length > 0 ">
                <!-- 外层 -->
                <el-sub-menu :index="v.name" :key="v.name" class="t-oh" v-if="!v.meta.hide" popper-class="sidebar-popper">
                    <template v-slot:title>
                        <span :class="['icon','iconfont','iconfontAc','icon-'+v.meta.iconAc]" v-if="v.meta.iconAc"></span>
                        <span :class="['icon','iconfont','icon-'+v.meta.icon]" v-if="v.meta.icon"></span>
                        <span class="theme-font t-oh">{{ v.meta.name }}</span>
                    </template>
                    <el-menu-item-group>
                        <my-nav :menuList="v.children"></my-nav>
                    </el-menu-item-group>
                </el-sub-menu>
            </div>
            <!-- 不存在子集 -->
            <div v-else>
                <!-- 内层 -->
                <el-menu-item
                    v-if="!v.meta.hide"
                    :key="v.name"
                    :index="v.name"
                    @click="gotoRoute(v.name)"
                >
                    <div class="line"></div>
                    <span :class="['icon','iconfont','iconfontAc','icon-'+v.meta.iconAc]" v-if="v.meta.iconAc"></span>
                    <span :class="['icon','iconfont','icon-'+v.meta.icon]" v-if="v.meta.icon"></span>
                    <span class="theme-font t-oh">{{ v.meta.name }}</span>
                </el-menu-item>
            </div>
        </template>
    </div>
</template>

<script>
import { defineComponent, reactive,onMounted,computed } from "vue";
import { toRaw } from '@vue/reactivity'
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
    name: "my-nav",
    props: {
        // 需要创建的侧边栏列表
        menuList: {
            type: Array,
            default: () => [],
        },
    },
    setup(props) {
        const store = useStore();
        let state = reactive({
            menuList: props.menuList,
            isSidebarNavCollapse: computed(()=>toRaw(store.getters.getIsSidebarNavCollapse)),
        });
        let router = useRouter();
        let gotoRoute = (name) => {
            sessionStorage.RouteInfo=name
            sessionStorage.RouteParams=JSON.stringify({})
            router.push({ name });
        };
        onMounted(()=>{
            // console.log('menu组件创建',state.isSidebarNavCollapse)
        })
        return {
            state,
            gotoRoute
        };
    },
});
</script>
<style lang="scss" scoped>
.line{
    display: none;
}


</style>