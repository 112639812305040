let config = {}
config.install = function (Vue) {
  // 全局属性配置
  Vue.config.globalProperties.config = {
    // input长度限制
    inputMaxlength:255,
    // 指令式loading属性配置
    loading:{
      text: "拼命加载中...",
      fileuptext: "文件上传中...",
      operationtext: "执行中...",
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0)'
    },
    // 限制
    limit:{
      number_rules:/[^\d]/g,//仅数字
      name_length:20,//人名长度限制
      name_rules:/[^\a-\z\A\u4E00-\u9FA5]/g//人名限制 只能输入汉字和字母
    },
    table:{
      background:'rgba(234, 196, 115, 0.1)',
      color:'#292728',
      fontWeight:'600',
      fontSize:'16px'
    }
  }
}
export default config