<template>
  <div class="main-container dp-f ai-c jc-sb">
    <div class="dp-f titleIcon ai-c">
      <img src="@/assets/img/nav/title_icon2.png" class="ml-44" v-if="ThemeTypeIndex==5">
      <img src="@/assets/img/nav/title_icon.png" class="ml-44" v-else>
      <oabutton class="ml-14" width='52' height='24' title="控制台" CSStype=5></oabutton>
    </div>
    <div class="dp-f ai-c">
      <!-- <el-color-picker v-model="color1" @change="colorChange" show-alpha /> -->
      <oabutton class="ml-14 mr-36" width='100' height='34' title="返回工作台" CSStype=5
        @buttonclick="gotoRoute('operation_worktable')"/>
      <el-input class="mr-36" v-model="input" placeholder="搜索功能与帮助">
        <template #append>
          <div class="line"></div>
          <div class="w-32 h100 dp-fc">
            <span class="icon iconfont icon-sousuo cu-p"></span>
          </div>
        </template>
      </el-input>
      <div class="line2 mr-25"></div>
      <div class="title mr-25 cu-p" @click="router_push_name('topHelper', undefined, undefined, true)">操作助手</div>
      <div class="line2 mr-25"></div>
      <el-popover :visible="visible" placement="bottom" :width="450" trigger="click"
        popper-class="el_popper_individual_center">
        <message></message>
        <template #reference>
          <img src="@/assets/img/nav/email2.png" class="w-32 h-32 mr-25 cu-p" v-if="ThemeTypeIndex==5">
          <img src="@/assets/img/nav/email.png" class="w-32 h-32 mr-25 cu-p" v-else>
        </template>
      </el-popover>
      <div class="line2 mr-25"></div>
      <div class="iconbox pt-r">
        <el-popover :visible="visible" placement="bottom" :width="300" trigger="click"
          popper-class="el_popper_individual_center">
          <individualCenter></individualCenter>
          <template #reference>
            <div class="pt-r cu-p">
              <img :src="userInfo.avatar" class="w-50 h-50 mr-25 icon" v-if="userInfo.avatar">
              <img src="@/assets/img/general/user.png" class="w-50 h-50 mr-25 icon" v-else>
              <realname class="realname pt-a" CSStype="1" is="true" v-if="userInfo.isCertification == 1"></realname>
            </div>
          </template>
        </el-popover>
      </div>
    </div>
  </div>
</template>
<script>
import { router_push_name } from "@/utils/server/router";
import { defineComponent, ref, onMounted, computed } from "vue";
import {useStore} from "vuex"
import individualCenter from "../individualCenter.vue"
import message from "../message/index.vue"
import realname from "@/components/icon/realname.vue"
export default defineComponent({
  components: { individualCenter, message, realname },
  setup() {
    const store = useStore();
    const ThemeTypeIndex = computed(() => {
      return store.state.theme.typeIndex
    })//当前选中主题下标
    // isAuthentication 企业实名认证 0是未认证 1是已认证
    // isCertification 个人实名认证状态 0是未认证 1是已认证
    const userInfo = computed(() => {
      return JSON.parse(window.sessionStorage.user).user_info
    })
    const color1 = ref('#EEC774')
    const input = ref()
    // 修改全局颜色方法
    const colorChange = ((value) => {
      console.log('修改全局颜色方法', value)
      window.document.getElementsByTagName('body')[0].style.setProperty('--active-theme', value);
    })
    // 保存sessionStorage 防止刷新
    const gotoRoute = (name) => {
      sessionStorage.RouteInfo = name
      sessionStorage.RouteParams = JSON.stringify({})
      router_push_name(name);
    };
    return {
      ThemeTypeIndex,
      userInfo,
      color1,
      router_push_name,
      input,
      colorChange,
      gotoRoute,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "@/components/nav/css/input.scss";
@import '@/styles/color/value.scss';

::v-deep .ret_button {
  border-radius: 4px;
}

.icon-sousuo {
  color: $active-theme2;
}

.main-container {
  width: 100%;
  height: 88px;
  background: $bg-ming;

  .titleIcon {
    img {
      width: 120px;
    }
  }

  .line2 {
    width: 1px;
    height: 24px;
    background: $bg-font-color;
    opacity: 0.4;
  }

  .title {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: $bg-font-color;
  }

  .iconbox {
    .icon {
      border-radius: 50%;
    }
  }
}

.realname {
  left: -8px;
  bottom: -4px;
}
</style>
