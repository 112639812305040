let host = (location.host).split(":")

// let serverURL='https://172.168.1.82'//一览数字
// let serverURL='http://192.168.110.212:8088' //一览数字
// let serverURL='http://192.168.110.215:8088' //一览数字
// let serverURL='https://dev.yilanshuzi.cn:2443'//一览数字
let serverURL='https://zhangyiqian.yilanshuzi.com'

export const configu= {
    //服务器地址
    serverURL:serverURL,
    // 文件接口地址
    fileapiURL:serverURL+'/sys-file',
    // 接口地址
    apiURL:serverURL,
    WebSocket:'',//websocket地址
    // 超时
    // timeout:60000,
    // filetimeout:60000,
    timeout:600000,
    filetimeout:600000,
    legalSealHeight: 70,
    entSealHeight: 155,
    personSignHeight: 34,
    personSignWidth: 134, 
    // 用户名密码登录的 client 信息
    formLoginClient: 'pig:pig',
    mode: 1,
}