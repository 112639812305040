<template>
  <el-dialog class="warningbox" v-model="state.dialogVisible" :close-on-click-modal="false" :width="state.dialogWidth">
    <template #header="{ close, titleId, titleClass }">
      <div class="dp-f headerbox ai-c">
        <img src="@/assets/img/general/warning.png" class="w-16 h-16 mr-8">
        {{ dialogVisibletitle }}
      </div>
    </template>
    <div v-if="state.baseInfo">
      <div class="mb-20">
        <el-text class="mx-1" type="info">请确认当前企业信息是否发生变更!<br> 如发生变更, 请点击<el-text class="mx-1" type="warning"> 去更新 </el-text>按钮更新数据</el-text>
      </div>
      <div>
        <el-form
          ref="ruleFormRef"
          :model="state.entInfo"
          label-width="140px"
          :label-position="state.labelPosition"
        >
          <el-form-item label="企业/组织/商户名称" prop="pass">
            <el-input v-model="state.entInfo.fullName" disabled/>
          </el-form-item>
          <el-form-item label="统一社会信用代码" prop="checkPass">
            <el-input v-model="state.entInfo.paperId" disabled/>
          </el-form-item>
          <el-form-item label="法定代表人姓名" prop="age">
            <el-input v-model="state.entInfo.leagalerName" disabled/>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div v-else>
      <div v-if="!state.upload">
        <el-form
          ref="ruleFormRef"
          :model="state.entInfo"
          label-width="140px"
          :label-position="state.labelPosition"
        >
          <el-form-item label="企业/组织/商户名称" 
            :rules="[{ required: true, message: '请输入企业/组织/商户名称' }]" 
            prop="fullName">
            <el-input v-model="state.entInfo.fullName"/>
          </el-form-item>
          <el-form-item label="统一社会信用代码" prop="checkPass">
            <el-input v-model="state.entInfo.paperId" disabled/>
          </el-form-item>
          <el-form-item label="法定代表人姓名" :rules="[{ required: true, message: '请输入法定代表人姓名' }]"  prop="leagalerName">
            <el-input v-model="state.entInfo.leagalerName"/>
          </el-form-item>
          <el-form-item label="法定代表人证件类型">
            <el-select v-model="state.entInfo.paperType">
              <el-option key="paperType" label="身份证" value="SF" />
            </el-select>
          </el-form-item>
          <el-form-item label="法定代表人证件号" 
          :rules="[{ required: true, type: number, message: '请输入法定代表人证件号码' }, 
                   {min: 18, max: 18, message: '身份证长度不正确'}]" 
          prop="leagalerpaperid">
            <el-input v-model="state.entInfo.leagalerpaperid"/>
          </el-form-item>
          <el-form-item label="法定代表人手机号" :rules="[{ validator: validPhoneData, trigger: 'blur' }]" prop="leagalerPhone">
            <el-input v-model="state.entInfo.leagalerPhone"/>
          </el-form-item>
        </el-form>
      </div>
      <div v-else>
        <div class="oletter allbox">
          <div class="w100 mb-10">
            <div class="labelbox dp-f h-22 ai-c">
              <span>*</span>
              <div class="mr-22">工商营业执照</div>
              <img src="@/assets/img/realname/example.png" class="w-16 h-16 mr-3 mt-2">
              <div class="download cu-p" @click="viewExample(['https://zhangyiqian.yilanshuzi.com/group1/M00/00/CC/wKgAXmQitfSAOu2FAA4HPInxe6Y191.png'])">查看示例</div>
            </div>
            <div class="mb-5 introduce">原件照片、扫描件或者加盖公章的复印件，支持.jpg .jpeg .bmp .png格式照片，大小不超过1M </div>
            <dragbox :file-size="1" ref="dragboxRef4"></dragbox>
          </div>
          <div v-if="configu.mode == 1">
            <div class="dp-f mb-10">
              认证授权公函
              <!-- <statusIcon class="ml-7" title="待提交" CSStype="0" v-if="state.authenticationState == 5"/> -->
            </div>
            <div class="mb-10">
              <div class="introduce mb-5">下载并打印授权公函后，加盖公章扫描或拍照上传电子版，须确保授权公函上的信息清晰可见,大小不超过1M</div>
              <div class="download dp-f ai-c">
                <div class="dp-f ai-c cu-p" @click="download">
                  <img src="@/assets/img/realname/file.png" class="w-16 h-16 mr-3">
                  下载《认证授权公函》模版
                </div>
                <!-- <span class="bg-999 h-14 w-1 mr-14 ml-14"></span>
                <div class="dp-f ai-c cu-p" @click="viewExample(['http://file.yilanshuzi.com/group1/M00/00/CC/wKgAXmQitd2AKtLfAAGa16corMs067.png'])">
                  <img src="@/assets/img/realname/example.png" class="w-16 h-16 mr-3">
                  查看示例
                </div> -->
              </div>
            </div>
            <div class="mb-20">
              <dragbox ref="dragboxRef"/>
            </div>
          </div>
          <div class="mb-6">
            身份证正反面
          </div>
          <div class="dp-f ai-c mb-6">
            <div class="introduce mr-20">请上传经办人身份证正反面，大小不超过1MB</div>
            <div class="download dp-f ai-c cu-p" @click="viewExample(['https://zhangyiqian.yilanshuzi.com/group1/M00/00/CC/wKgAXmQitBmAf0ZVAA2Jfp2vU0E325.png'])">
              <img src="@/assets/img/realname/example.png" class="w-16 h-16 mr-3">
              查看示例
            </div>
          </div>
          <div class="dp-f mb-16">
            <div class="w-200 h-140 mr-12">
              <dragbox :file-size="1" ref="dragboxRef2" :cssType="2"/>
            </div>
            <div class="w-200 h-140">
              <dragbox :file-size="1" ref="dragboxRef3" :cssType="3"/>
            </div>
          </div>
          <!-- <oabutton  v-model:loading="state.loading1" class="mb-50" width='106' height='48'
            title="提交" CSStype=2 style="border-radius: 4px;font-size: 16px;"  @buttonclick="submit1()"></oabutton>              -->
        </div>
        <el-image-viewer :url-list="dialogImageUrl" v-if="dialogVisibleImage" @close="dialogVisibleImage = false" />
      </div>
    </div>
    
    <template #footer>
      <span class="dialog-footer dp-f fd-rr" v-if="state.baseInfo">
        <oabutton style="border-radius: 2px;" v-if="configu.mode != 1" class="searcML" width='66' height='32' title="没有变更" CSStype=1
          @buttonclick="updateCert"
          v-model:loading="dialogloading" />
        <oabutton style="border-radius: 2px;" class="searcML" width='66' height='32' title="去更新" CSStype=2
          @buttonclick="showModify" />
      </span>
       <span class="dialog-footer dp-f fd-rr" v-else>
        <oabutton style="border-radius: 2px;" class="searcML" width='66' height='32' title="取消" CSStype=1
          @buttonclick="show"
          v-model:loading="dialogloading" />
      <oabutton v-if="!state.upload" style="border-radius: 2px;" class="searcML" width='66' height='32' title="下一步" CSStype=2
          @buttonclick="nextStep" />
      <oabutton v-else style="border-radius: 2px;" class="searcML" width='66' height='32' title="下一步" CSStype=2
          @buttonclick="updateCert(true)" />
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, onMounted, nextTick } from 'vue'
import { handleMessage } from "@/utils/server/confirm";
import { httpToken } from "@/utils/request";
import { uploadFile } from "@/utils/server/upload.js"
import { selectById } from "@/utils/server/getdata.js"
import { useStore } from "vuex";
import { configu } from '@/utils/config'//引入公用文件
import { validPhone } from "@/utils/server/validate.js";
import dragbox from './dragbox.vue';
const dialogloading = false;// 弹框按钮loading 显示
const dialogVisible = false//弹框显示
const dialogVisibletitle = "请确认企业信息"//弹框标题
const dialogVisibleImage = ref(false)//缩略图预览弹框
const dialogImageUrl = ref([])//预览图片路径
const ruleFormRef = ref()
const dragboxRef = ref()//认证授权公函
const dragboxRef2 = ref()//身份证正面
const dragboxRef3 = ref()//身份证反面
const dragboxRef4 = ref()//工商营业执照

// 查看示例
const viewExample=(urls)=>{
  dialogImageUrl.value=urls
  dialogVisibleImage.value = true
}
const download=()=>{
	window.open("https://zhangyiqian.yilanshuzi.com/group1/M00/00/CC/wKgAXmQitpeARCTSAABAAFLBceA910.doc?attname=《认证授权公函》模版.doc")
}

const validPhoneData = (rule, value, callback) => {
  if (!validPhone(value)) {
      callback(new Error('手机号格式错误'))
  } else {
    callback()
  }
}
const state = reactive({
  dialogVisible: false,
  interests: [],//权益
  entInfo: {},
  baseInfo: true,
  upload: false,
  labelPosition: "top",
  dialogWidth: 400,
})

const nextStep = async () => {
  await unref(ruleFormRef).validate( (valid, item) => {
    console.log("nextStep:", valid)
    if (valid) {
      console.log("nextStep:", valid)
      state.upload = true
    }
  })
}

// 清除已认证企业
const check=()=>{
  dialogloading=true
  httpToken({
    method: "post",
    url: '/admin/sys-user/cleanUpEnterpriseInfo',
  }).then((res) => {
    handleMessage('已退出登录企业,切换为个人帐号!','success')
    switchClick()
    dialogVisible=false
  }).catch(()=>{ 
    dialogloading=false
  })
}
const updateCert = (flg = false) => {
  let promiseall = []
  let files = []
  if (flg) {
    if (!unref(dragboxRef4).getFile()) {
      handleMessage('请先上传工商营业执照')
      return
    }
    if (configu.mode == 1){
      if (!unref(dragboxRef).getFile()) {
        handleMessage('请先上传认证授权公函')
        return
      }
    }
    if (!unref(dragboxRef2).getFile()) {
      handleMessage('请先上传身份证正面')
      return
    }
    if (!unref(dragboxRef3).getFile()) {
      handleMessage('请先上传身份证反面')
      return
    }

    // let officialFetterFileId//认证公函文件id
    // let frontIDFileId//身份证正面文件id
    // let reverseIDFileId//身份证反面文件id
    // let businessFileId//认证授权公函id

    if (configu.mode == 1) {
      let p1=uploadFile(unref(dragboxRef).getFile().file, "certification_officialFetterFileId").then((res) => {
        // officialFetterFileId=res.data.id
        let file = {
          id: res.data.id,
          name: '授权函',
          type: 91,
          isLocal: 0,
          fileUrl: res.data.fileUrl
        }
        files.push(file)
      })
      promiseall.push(p1)
    } 

    let p2=uploadFile(unref(dragboxRef2).getFile().file, "certification_frontIDFileId").then((res) => {
      let file = {
        id: res.data.id,
        name: '身份证正面',
        type: 35,
        isLocal: 0,
        fileUrl: res.data.fileUrl
      }
      files.push(file)
    })
    promiseall.push(p2)
    let p3=uploadFile(unref(dragboxRef3).getFile().file, "certification_reverseIDFileId").then((res) => {
      let file = {
        id: res.data.id,
        name: '身份证反面',
        type: 36,
        isLocal: 0,
        fileUrl: res.data.fileUrl
      }
      files.push(file)
    })
    promiseall.push(p3)
    let p4=uploadFile(unref(dragboxRef4).getFile().file, "certification_businessFileId").then((res) => {
      let file = {
        id: res.data.id,
        name: '营业执照',
        type: 33,
        isLocal: 0,
        fileUrl: res.data.fileUrl
      }
      files.push(file)
    })
    promiseall.push(p4)
  }
  Promise.all(promiseall).then(() => {
    state.entInfo.update = flg
    state.entInfo.files = files
    state.entInfo.mode = configu.mode
    httpToken({
      method: "post",
      url: "/admin/certification/entUpdateCert",
      data: state.entInfo
    }).then(res => {
      state.dialogVisible = false
      window.sessionStorage.setItem("certstatus", 1) 
      handleMessage('信息确认成功!','success')
    }).catch(() => {
      console.log("error======")
    })
  })
}
const userInfo = JSON.parse(window.sessionStorage.user).user_info
const certStatus = window.sessionStorage.certstatus
onMounted(() => {
  if (userInfo.sysCompany && userInfo.sysCompany.isauthentication == 1 && userInfo.roleType == 1) {
    state.entInfo.fullName = userInfo.sysCompany.companyName
    state.entInfo.paperId = userInfo.sysCompany.creditCode
    state.entInfo.leagalerName = userInfo.sysCompany.juridicalPersonName
    state.entInfo.paperType = "SF"
    state.entInfo.entId = userInfo.sysCompany.companyId
  }
  console.log("certstatus: ", certStatus)
})

const show = () => {
  state.upload = false
  if (userInfo.sysCompany && userInfo.sysCompany.isauthentication == 1 && userInfo.roleType == 1 && certStatus > 1) { //企业管理员 证书需要更新
    state.dialogVisible = true
    state.baseInfo = true 
    state.labelPosition = "top"
    state.dialogWidth = 400
  } else {
    state.dialogVisible = false
  }
  console.log("userinfo: ", userInfo)
}

const showModify = () => {
  if (userInfo.sysCompany && userInfo.sysCompany.isauthentication == 1 && userInfo.roleType == 1) {
    state.baseInfo = false
    state.labelPosition = "right"
    state.dialogWidth = 500
    state.dialogVisible = true
  }
}

defineExpose({
  show,
  showModify,
});
</script>
<style lang="scss" scoped >
// @import "@/styles/general/element.scss";  //element 样式重置
@import '@/styles/color/value.scss';

.warning {
  width: 100%;
  background: $active-alpha;
  border-radius: 8px;
  padding-left: 27px;
  padding-top: 14px;
  padding-bottom: 14px;

  .icon {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #999999;
  }

  .interestItem+.interestItem {
    margin-top: 14px;
  }

  font-size: 12px;
  font-family: PingFangSC-Regular,
  PingFang SC;
  font-weight: 400;
  color: #666666;

  .title {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }
}
.oletter {
	// padding-top: 60px;
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #333333;

	.introduce {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
	}

	.download {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #1890FF;
	}
}
.allbox{
	// width: 760px;
	margin: 0 auto;
	// padding: 46px 40px;
	background: #FFFFFF;
	border-radius: 12px;
}
</style>