//把驼峰改为下划线
export function titleCase(str) {
  return str.replace(/[A-Z]/, function (word) {
    return '_' + word.toLowerCase()
  })
}
// 处理树型数据
export function setTreedata(data,datatype = 1){
  data.map(item => {
    if(datatype==1){
      item.label = item.name
      item.value = item.id
    }
    if(item.children)
      setTreedata(item.children)
  })
}
// 防抖函数
export function debounce(fn,time=1000){
  let timer
  return function () {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn(...arguments)
    }, time)
  }
}
//数组删除指定项
export function arrDeteleItem(arr,el,key,type=1){
  arr.forEach((item,index) => {
    if(item[key]==el[key]){
      if(type==1){
        arr.splice(index,1)
      }else{
        item.ishide=true
      }
    }
  });
}
// 转换金额 ispoint 是否隐藏小数点
export function convert(money,ispoint=true) {
  let s = money; /**获取小数型数据**/
  s += "";
  if (s.indexOf(".") == -1) s += ".0"; /**如果没有小数点，在后面补个小数点和0**/
  if (/\.\d$/.test(s)) s += "0"; /**正则判断**/
  while (/\d{4}(\.|,)/.test(s)) /**符合条件则进行替换**/
    s = s.replace(/(\d)(\d{3}(\.|,))/, "$1,$2"); /**每隔3位添加一个**/
  if(ispoint)
    s=s.replace('.00','')
  return s;
}