<template>
  <div class="table_layout">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped >
.table_layout{
  width: 100%;
  // display: flex;
  // padding: 0 2%;
  // 解决element表格宽度动态切换失败问题
  overflow: auto;
  // background:rgb(204, 244, 244);
}
</style>