<template>
  <div v-loading="loading"
    :element-loading-background="config.loading.background" 
    :class="['button','ret_button','dp-fc','button'+CSStype,disabled?['disabled','disabled'+distype]:'']" :style="{width:width+'px',height:height+'px'}" @click="click">
    <img v-if="imgname" :src="require('./img/'+imgname)" :class="imgname.split('.')[0]">
    {{title}}
  </div>
</template>

<script>
import { defineComponent, } from 'vue'

export default defineComponent({
  props: {
    //loading 显示
    loading: {
      type: Boolean,
      default: false
    },
    // css 类型
    CSStype: {
      type: Number,
      default:1
    },
    disabled: {
      type: Boolean,
      default: false
    },
    // 禁用时的样式
    distype: {
      type: Number,
      default:1
    },
    //  :debouncedis=true
    // 是否为防点击按钮 fasle为普通按钮  true为防抖按钮
    debouncedis: {
      type: Boolean,
      default: false
    },
    // 防抖触发间隔
    delay:{
      type: Number,
      default: 1000
    },
    width: {
      type: Number,
      default: 58
    },
    height: {
      type: Number,
      default: 40
    },
    title: {
      type: String,
      default: '标题'
    },
    // 图片名称
    imgname:{
      default: false
    }
  },
  setup(props,context) {
    let click=(()=>{
      if(!props.disabled){//判断是否为禁用状态
        if(props.loading===true){return}//loading显示阻止执行
        if(props.debouncedis){//触发防抖交验
          debounceclick()
        }else{
          context.emit('buttonclick')
        }
      }
    })
    const debounceclick = debounce(()=>{
      context.emit('buttonclick')
    })
    // 防抖函数
    function debounce(fn){
      let timer
      return function () {
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          fn()
        }, props.delay)
      }
    }
    return {
      click
    }
  },
})
</script>
<style lang="scss" scoped >
@import '@/styles/color/value.scss';
.button{
  cursor: pointer;
  transition: all 0.5s;
  // border-radius: 4px;
  line-height: 14px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  img{
    margin-right: 10px;
  }
  .darkAdd,.darkAdd2{
    width: 22px;
    height: 22px;
  }
}
.disabled{
  cursor: auto;
  background: #F8F8F8!important;
  border: 1px solid #D9D9D9!important;
  color: #CFCFCF!important;
}
.disabled2{
  background: #E9E9E9!important;
  border:none!important;
  color: #999999!important;
}
.button1{
  color: #292728;
  border: 1px solid #E0E0E0;
}
.button2{
  color: $color-button;
  background: $active-theme;
}
:not(.disabled).button2:hover{
  opacity: 0.8;
}
.button3{
  color: #292728;
  border: 1px solid $active-theme;
}
.button4{
  color: $active-theme;
  background-color: $active-alpha;
  border: none;
}
.button5{
  color: $active-theme3;
  background: $active-theme2;
}
.button7 {
  border: 1px solid #3377E7;
  color: #3377E7;
  border-radius: 15px;
}
.button8 {
  background-color: #2890FF;
  color: #fff;
  font-size: 18px;
}
.button9 {
  background-color: $active-theme;
  color: $color-button;
  font-size: 18px;
}
.button10 {
  border: 1px solid #2890FF;
  color: #2890FF;
  border-radius: 4px;
}
</style>