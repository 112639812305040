// 获取状态(用户)
export function getstatus(code) {
  return code == 0?'正常':code == 1?'锁定':'未知'
}
// 菜单类型
export function getmenuType(code) {
  return code == 0?'菜单':code == 1?'按钮':'未知'
}
// 获取性别
export function getsex(code) {
  return code == 0?'男':code == 1?'女':'未知'
}
// 获取状态(通讯录)
export function getisCertification(code) {
  return code == 0?'未认证':code == 1?'已认证':'未知'
}
// 合同状态
export function getcontractStatus(code) {
  return code == 0?'待我处理':code == 1?'签署中':code == 2?'已完成':code == 3?'已过期':code == 4?'草稿箱':code == 5?'抄送':'未知'
}
// 获取企业认证状态
export function getauthenticationState(code) {
  return code == 0?'基本信息认证中':code == 1?'基本信息认证完成':code == 2?'经办人法定代表人信息填写':code == 3?'待选择认证方式':code == 4?'对公账户验证中':code == 5?'对公账户验证完成':code == 6?'等待资料提交':code == 7?'资料提交失败':code == 8?'认证完成':code == 9?'已撤销':code == 10?'认证失败':'未知'
  // 认证状态0：基本信息认证中 1：基本信息认证完成 2经办人法定代表人信息填写 3：待选择认证方式4：对公账户验证中5：对公账户验证完成6：等待资料提交7：资料提交失败8认证完成9：已撤销 10认证失败
}
// 实名状态
export function getisCertificationStatus(code) {
  return code == 0?'未实名':code == 1?'已实名':'未知'
}
// 公司申请状态
export function getisApplyStatus(code) {
  return code == 0?'待审核':code == 1?'已通过':code == 2?'已拒绝':'未知'
}
// 获取印章状态
export function getsignStatus(code) {
  return code == 1?'通过':code == 2?'停用':code == 3?'待审核':code == 4?'未通过':'使用中'
}
// 获取印章权限状态
export function getsignPermissionStatus(code) {
  return code == 1?'正常':code == 2?'已过期':'未知'
}
// 获取印章使用状态
export function getsignUseStatus(code){
  return code == 1?'使用中':code == 2?'已过期':code==3?'待审批':'未知'
}
// 获取认证方式状态
export function getAuthenticationTypeStatus(code){
  return code == 1?'人脸识别认证':code == 2?'手机号认证':code==3?'银行卡认证':'未知'
}
// 获取企业认证方式状态
export function getEqAuthenticationTypeStatus(code){
  return code == 4?'企业法人核验':code == 7?'对公转账核验':'其他'
  // return code == 0?'企业':code == 1?'个体工商户':code==2?'其他':'未知'
}
// 获取发票类型
export function getinvoiceType(code){
  return code == 1?'增值税普通发票':code == 0?'增值税专用发票':'未知'
}
//印章类型
export function getsealtype(code){
  return code == 1?'个人签名':code == 2?'个人印章':code == 3?'公章':code == 4?'法定代表人章':'业务章'
}
// 签署状态
export function getSignOrder(code) {
  return code == 0?'无序签署':code == 1?'有序签署':'未知'
}
// 获取签署状态
export function getsignStatus2(code) {
  return code == 0?'未签署':code == 1?'已签署':'未知'
}
// 获取订单类型（0：线上充值，1：对公账户）
export function getrechargeType(code) {
  return code == 0?'线上充值':code == 1?'对公账户':'未知'
}
// 支付渠道（0：支付宝，1：微信，2：线下汇款）
export function getpaymentMethod(code) {
  return code == 0?'支付宝':code == 1?'微信':code == 2?'线下汇款':'未知'
}
// 支付状态(0-订单生成, 1-支付中, 2-支付成功, 3-支付失败, 4-已撤销, 5-已退款, 6-订单关闭)
export function getpaymentStatus(code) {
  return code == 0?'订单生成':code == 1?'支付中':code == 2?'支付成功':code == 3?'支付失败':code == 4?'已撤销':code==5?"已退款":"订单关闭"
}
// 是否开票(0未开票，1已开票，2待审核 3已拒绝)
export function getinvoicing(code) {
  return code == 0?'未开票':code == 1?'已开票':code == 2?'待审核':code == 3?'已拒绝':'未知'
}
// 消费类型（0：合同）
export function getconsumeType(code) {
  return code == 0?'合同':code == 1?'套餐':'未知'
}
//签署位置名称结尾
export function getqianDetail(code) {
  return code == 0?'企业签章':code == 1?'经办人签字':code == 2?'法定代表人签章':''
}