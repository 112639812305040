<template>
  <div class="titlebox dp-f">
    <div class="icon mr-12"></div>
    <div class="title">{{title}}</div>
    <div class="more" @click="click" v-if="moreshow">更多</div>
    <div class="slot" v-if="slotshow">    
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent, } from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '标题'
    },
    moreshow: {
      type: Boolean,
      default: false
    },
    slotshow: {
      type: Boolean,
      default: false
    },
  },
  setup(props,context) {
    let click=(()=>{
      context.emit('more')
    })
    return {
      click
    }
  },
})
</script>
<style lang="scss" scoped >
.titlebox{
  width: 100%;
  height: 18px;
  line-height: 18px;
  margin-bottom: 30px;
  .icon{
    width: 4px;
    height: 18px;
    background: #0BB189;
  }
  .title{
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #292728;
  }
  .more{
    margin-left: auto;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #0BB189;
    cursor: pointer;
  }
  .slot{
    margin-left: auto;
  }
}
</style>