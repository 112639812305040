<template>
	<div class="item mb-8 dp-f" v-for="(el, ind) in props.items" :key="ind">
		<!-- signerType签署人类型（0：个人，1：公司） -->
		<div class="w100 box">
			<div class="pt-r signItem" v-for="(item, index) in el.contractSignPersonList">
				<div class="t-ohs company" v-if="el.signerType == 1">{{ item.companyName }}</div>
				<div class="t-ohs">签署人:{{ item.signerName + '(' + item.signerPhone + ')'}}</div>
				<span class="icon h-22 lh-22" :class="'icon' + item.signStatus">{{ getsignStatus2(item.signStatus) }}</span>
			</div>
		</div>
		<!-- signStatus 是否签署(0 未签署, 1 已签署) -->
	</div>
</template>
<script setup>
import { getsignStatus2 } from "@/utils/server/getcode.js"
const props = defineProps({
	status: {
		default: 1
	},
	items: {
		type: Array, // 参数类型
		default: [], //默认值
	},
})
</script>
<style lang="scss" scoped >
.item {
	width: 100%;
	padding: 20px 30px;
	background: #FFFFFF;
	border-radius: 8px;
	border: 1px solid #D9D7D7;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #333333;

	.company {
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
	}

	.box {
		.signItem+.signItem {
			margin-top: 16px;
		}
	}

	.signItem {
		div+div {
			margin-top: 10px;
		}
	}

	.icon {
		position: absolute;
		right: 0;
		top: 0;
		flex-shrink: 0;
		padding: 0 4px;
		background: rgba(22, 119, 255, 0.1);
		border-radius: 4px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #1677FF;
	}

	.icon1 {
		background: rgba(250, 173, 20, 0.1);
		color: #FAAD14;
	}
}</style>