// 控制台 子级不需要经过后端的动态路由
export const hideRoutes = [
    {
        path: "sign_sealParticulars",
        component: () => import("@/views/sign/sealParticulars.vue"),
        name: "sign_sealParticulars",
        meta: {
            superiors: ['电子印章管理'],//自定义上级面包屑
            name: "印章详情",
        },
    },
]
//工作台 子级不需要经过后端的动态路由
export const hideRoutes2 = [
    {
        path: "operation_myseal_detail",
        component: () => import("@/views/operation/myseal/detail.vue"),
        name: "operation_myseal_detail",
        meta: {
            name: "用户管理",
        },
    },
    {
        path: "operation_initiate_contract",
        component: () => import("@/views/operation/initiateContract/index.vue"),
        name: "operation_initiate_contract",
        meta: {
            name: "发起合同",
        },
    },
    {
        path: "account_topupsubject",
        component: () => import("@/views/account/topupsubject.vue"),
        name: "account_topupsubject",
        meta: {
            name: "发起合同",
        },
    },
    {
        path: "sign_details",
        component: () => import("@/views/operation/signContract/components/signDetails.vue"),
        name: "sign_details",
        meta: {
            name: "签署详情",
        },
    },
    {
        path: "user",
        component: () => import("@/views/user/index.vue"),
        name: "user",
        meta: {
            name: "个人中心",
        },
    },
    {
        path: "create_page",
        component: () => import("@/views/operation/myTemplate/createPage.vue"),
        name: "create_page",
        meta:{
            name: "创建模板",
        },
    },
    {
        path: "topHelper",
        component: () => import("@/views/user/topHelper.vue"),
        name: "topHelper",
        meta:{
            name: "操作助手",
        },
    },
    {
        path: "message",
        component: () => import("@/views/user/message.vue"),
        name: "message",
        meta:{
            name: "消息通知",
        },
    },
    {
        path: "mysign_sealParticulars",
        component: () => import("@/views/operation/myseal/sealParticulars.vue"),
        name: "mysign_sealParticulars",
        meta: {
            name: "印章详情",
        },
    },
]
// 树形需要经过后端的动态路由
export const DynamicRoutes = [
    {
        path: "",
        component: () => import("@/components/nav/nav.vue"),
        name: "container",
        meta: {
            name: "首页",
        },
        children: [
            {
                path: "home",
                component: () => import("@/views/home/home.vue"),
                name: "home",
                meta: {
                    // icon:'shezhi',
                    name: "首页",
                },
            },
            {
                path: "system",
                component: () => import("@/views/system/index.vue"),
                name: "system",
                meta: {
                    icon: 'shezhi',
                    name: "系统设置",
                },
                children: [
                    {
                        path: "user",
                        component: () => import("@/views/system/views/user.vue"),
                        name: "user",
                        meta: {
                            name: "用户管理",
                        },
                    },
                    {
                        path: "role",
                        component: () => import("@/views/system/views/role.vue"),
                        name: "role",
                        meta: {
                            name: "角色管理",
                        },
                    },
                    {
                        path: "department",
                        component: () => import("@/views/system/views/department.vue"),
                        name: "department",
                        meta: {
                            name: "部门管理",
                        },
                    },
                    {
                        path: "post",
                        component: () => import("@/views/system/views/post.vue"),
                        name: "post",
                        meta: {
                            name: "岗位管理",
                        },
                    },
                    {
                        path: "menu",
                        component: () => import("@/views/system/views/menu.vue"),
                        name: "menu",
                        meta: {
                            name: "菜单管理",
                        },
                    },
                ]
            }, ...hideRoutes
        ]
    },
]