<template>
  <div class="leftbox dp-f">
    <div class="sidebar theme-bg">
      <div class="menubox">
        <el-menu :collapse="state.isSidebarNavCollapse" text-color="#eee" active-text-color="#000"
          :default-active="state.currentMenu" id="menu" :unique-opened="true">
          <MENU :menuList="state.sidebarMenu"></MENU>
        </el-menu>
        <div class="botbox  dp-f jc-sb pt-24" v-if="!state.isSidebarNavCollapse">
          <oabutton width='100' height='34' title="进入官网" CSStype=5 @buttonclick="EnterClick()"
            style="border-radius: 4px;"></oabutton>
            <span class="icon iconfont icon-zhankai w-24 h-24 cu-p mt-7 tr50" @click="toggleNavCollapse"></span>
        </div>
        <div class="botbox botbox2 dp-f jc-sb pt-24" v-else>
          <span class="icon iconfont icon-zhankai w-24 h-24 cu-p mt-7 tr50" @click="toggleNavCollapse"></span>
        </div>
      </div>
    </div>
    <div class="routerbox">
      <indicate v-if="route.name != 'home'"></indicate>
      <div class="showbox" :class="'showbox_' + route.name">
        <!-- 渲染时增加参数的key值防止同页面不刷新问题  存在问题 相同传参页面不刷新-->
        <router-view :key="$route.params"></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { defineComponent, reactive, computed, onMounted } from "vue";
import indicate from "../indicate.vue"
import MENU from "./menu.vue";
import { useRoute } from "vue-router";
export default defineComponent({
  setup() {
    const route = useRoute()
    const store = useStore();
    const state = reactive({
      sidebarMenu: computed(() => store.getters.GET_MENU),
      currentMenu: computed(() => store.getters.GET_CURRENTMENU),
      isSidebarNavCollapse: computed(() => store.getters.getIsSidebarNavCollapse),
    });
    const EnterClick = () => {
      window.open('http://yilanshuzi.cn/#/', '_blank');
    }
    // 收缩侧边栏
    let toggleNavCollapse = () => {
      store.commit("toggleNavCollapse");
    };
    onMounted(() => {
      // console.log('侧边栏数据',state.sidebarMenu)
    })
    return {
      route,
      state,
      EnterClick,
      toggleNavCollapse,
    };

  },
  components: {
    MENU, indicate
  },
});
</script>
<style lang="scss">
@import '@/styles/color/value.scss';
.leftbox {
  width: 100%;
  height: 100%;

  .sidebar {
    flex-shrink: 0;
    overflow: auto;
    position: relative;

    .menubox {
      height: calc(100% - 100px);
      overflow: auto;
    }

    .menubox::-webkit-scrollbar {
      display: none;
    }

    .botbox {
      width: calc(100% - 60px);
      height: 100px;
      position: absolute;
      bottom: 0px;
      left: 30px;
      border-top: 1px solid $bg-font-color-alpha;
      span{
        color: $active-theme2;
      }
    }

    .botbox2 {
      width: 0;
      left: 20px;

      span {
        transform: rotateY(180deg);
      }
    }
  }
}

.el-menu {
  background-color: transparent !important;
}

.routerbox {
  .showbox {
    height: calc(100% - 48px);
    overflow: auto;
  }

  // 首页无面包屑情况
  .showbox_home {
    height: 100%;
  }
}
</style>