//引入axios库
import axios from 'axios';
// import {useStore} from "vuex";
import {configu} from '@/utils/config'//引入公用文件
import {setMsgbox} from "@/utils/server/setMsgbox.js"
import { handleMessage } from '@/utils/server/confirm'
export const filehttp = axios.create({
    baseURL: configu.apiURL,//接口统一域名
    timeout: Number(configu.filetimeout) //设置超时
})
export const filehttp_token = axios.create({
    baseURL: configu.apiURL,//接口统一域名
    timeout: Number(configu.filetimeout) //设置超时
})
// filehttp_token请求拦截器
filehttp_token.interceptors.request.use(
    (configu) => {
        if (sessionStorage.token) {
            configu.headers['Authorization'] ='Bearer '+ sessionStorage.token
        }
        return configu
    },
    (error) => {
        console.log("filehttp_token_error", error) // for debug
        return Promise.reject(error)
    }
)
export const filehttpToken = axios.create({
    baseURL: configu.fileapiURL,//接口统一域名
    timeout: Number(configu.filetimeout) //设置超时
})
// filehttpToken请求拦截器
filehttpToken.interceptors.request.use(
    (configu) => {
        if (sessionStorage.token) {
            configu.headers['Authorization'] ='Bearer '+ sessionStorage.token
        }
        return configu
    },
    (error) => {
        console.log("httpToken_error", error) // for debug
        return Promise.reject(error)
    }
)
// filehttpToken响应拦截器
filehttpToken.interceptors.response.use(
    (res) => {//相应拦截
        return setMsgbox(res,2)
    },
);
export const httpToken=axios.create({
    baseURL: configu.apiURL,//接口统一域名
    timeout: Number(configu.timeout) //设置超时
})
// 请求拦截器
httpToken.interceptors.request.use(//请求拦截
    configu => {
        // console.log('data111111111111')
        if (sessionStorage.token) {
            configu.headers['Authorization'] ='Bearer '+ sessionStorage.token
        }
        return configu
    },
    error => {
        // console.log("httpToken_error", error) // for debug
        return Promise.reject(error)
    }
)
// 响应拦截器
httpToken.interceptors.response.use(
    (res) => {//相应拦截
        // console.log('httpToken拦截器',res)
        return setMsgbox(res)
    },
    err=>{
        console.log('httpToken失败拦截器',err)
        // handleMessage("请求失败，请重新登陆")
        if (err && err.response && err.response.status == 424) {
            handleMessage("登录已失效，请重新登陆")
        }
        return Promise.reject(err)
    }
);
export default httpToken