// import {computed} from 'vue'
import { handleCofirm, handleMessage } from "@/utils/server/confirm";
// isauthentication 企业实名认证 0是未认证 1是已认证
// isCertification 个人实名认证状态 0是未认证 1是已认证
// 获取当前用户是否实名
export function getUserRealname(){
  let userInfo = JSON.parse(window.sessionStorage.user).user_info
  if(userInfo.isCertification==1){//已实名
		return true
  }else{
    return false
  }
}
//判断个人还是公司进合同发起页面
export function getUserOrCompany(type=1){
	let userInfo = JSON.parse(window.sessionStorage.user).user_info
	if(userInfo.sysCompany){//企业
		if(userInfo.sysCompany&&userInfo.sysCompany.isauthentication==1){//已实名
			return 1
		}else{
			if(type==1)
				handleMessage('请先完成企业实名认证')
			return 2
		}
	}else{//个人
	  if(userInfo.isCertification==1){
			return 1
		}else{
			if(type==1)
				handleMessage('请先完成个人实名认证')
			return 3
		}
	}
}
// 获取当前企业是否实名
export function getCompanyRealname(){
  let userInfo = JSON.parse(window.sessionStorage.user).user_info
  if(userInfo.sysCompany&&userInfo.sysCompany.isauthentication==1){//已实名
		return true
  }else{
    return false
  }
}
export function clickUserCheck(callback=()=>{}){
	if(getUserRealname()){
		callback()
	}else{
		handleMessage('请先完成个人实名认证')
	}
}
export function clickCompanyCheck(callback=()=>{}){
	if(getUserRealname()){
		callback()
	}else{
		handleMessage('请先完成个人实名认证')
	}
}