<template>
  <div class="individualCenter dp-f jc-c fw-w">
    <img :src="userInfo.avatar" class="w-50 h-50 icon cu-p mt-24 mb-15" v-if="userInfo.avatar">
    <img src="@/assets/img/general/user.png" class="w-50 h-50 icon cu-p mt-24 mb-15" v-else>
    <div class="w100 ta-c fs-18 mb-12">{{ DesChname }}</div>
    <div class="w100 ta-c c-999 mb-12">账号：{{ DesPhone }}</div>
    <div v-for="(el, ind) in showTitle" :key="ind" class="w100 h-50 dp-f ai-c operationItem cu-p"
      @click="store.commit('route/setindividualCenterIndex', el.index), router_push_name('user', { index: el.index }, undefined, true)">
      <!-- <img :src="el.iconUrl" class="w-16 h-16 mr-10"> -->
      <span class="icon iconfont  w-16 h-16 dp-fc mr-10" :class="'icon-' + el.iconfont"></span>
      <div>{{ el.title }}</div>
      <img src="@/assets/img/nav/right.png" class="ml-at w-16 h-16">
    </div>
    <oabutton class="mt-15 mb-10" width='300' height='48' title="退出登录" CSStype=4
      style="border-radius: 4px;font-size: 18px;" @buttonclick="logout" distype="2"></oabutton>
  </div>
</template>
<script setup>
import { reactive, ref, unref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { loginOut } from "@/api/login"
import { chnameDesensitization, phoneDesensitization } from "@/hooks/desensitization.js"
import { router_push_name } from "@/utils/server/router"
const store = useStore();
const state = reactive({
  isCertification: false,//是否实名认证
  operations: [
    { title: '账号与设置', index: 0, iconfont: 'shezhi' },
    { title: '实名认证', index: 1, iconfont: 'shiming-xianxing' },
    { title: '我的签名', index: 2, iconfont: 'qianming' },
    { title: '我的企业', index: 3, iconfont: 'wodeqiye' },
    // { title: '我的账户', index: 4, iconfont: 'wodeqiye' },
    // { title: '套餐购买', index: 5, iconfont: 'wodeqiye' },
  ],//操作列表
})
const userInfo =computed(()=>{
    return JSON.parse(window.sessionStorage.user).user_info
})
const showTitle = computed(() => {
  if (userInfo.roleType != 1 && userInfo.sysCompany) {
    return state.operations
  } else {
    state.operations.push({ title: '我的账户', index: 4, iconfont: 'wodeqiye' })
    state.operations.push({ title: '套餐购买', index: 5, iconfont: 'wodeqiye' })
    state.operations.push({ title: '发票管理', index: 6, iconfont: 'wodeqiye' })
    return state.operations
  }
})
const DesChname = chnameDesensitization(JSON.parse(window.sessionStorage.user).user_info.chnname)
const DesPhone = phoneDesensitization(JSON.parse(window.sessionStorage.user).user_info.phone)
// 退出登录
const logout = (() => {
  loginOut()
})
</script>
<style lang="scss" scoped >
@import '@/styles/color/value.scss';

.iconfont {
  color: $active-theme;
  font-size: 20px;
}

.individualCenter {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;

  .icon {
    border-radius: 50%;
  }

  .operationItem {
    border-bottom: 1px solid #F0F0F0;
  }
}</style>