//固定路由
import router from "@/router/index";
import store from "../store"
import { hideRoutes, hideRoutes2 } from "@/router/route";
import view from "@/components/nav/nav.vue";
import { clone, setDefaultRoute } from "@/utils/server/clone"
import { httpToken } from "@/utils/request";
import { handleMessage } from "@/utils/server/confirm";
import { router_push_name } from "@/utils/server/router";
const actions = {
  // 处理动态路由
  async FETCH_PERMISSION({ commit },type,contractId) {
    // console.log('window.sessionStorage.MENU',window.sessionStorage.MENU)
    // console.log('JSON.parse(window.sessionStorage.user).user_info', JSON.parse(window.sessionStorage.user).user_info)
    // 判断是否存在缓存的菜单数据 如果存在直接使用 不存在调接口
    if (window.sessionStorage.MENU||window.sessionStorage.MENU2) {
      if (window.sessionStorage.MENU2) {
        setmenu(getmenudata(JSON.parse(window.sessionStorage.MENU)), getmenudata(JSON.parse(window.sessionStorage.MENU2)))
      } else {
        setmenu(getmenudata(JSON.parse(window.sessionStorage.MENU)))
      }
    } else {
      httpToken({
        method: "post",
        url: '/admin/sys-menu/getUserMenu',
      }).then(res => {
        // console.log('动态菜单数据',res.data)
        let show = false
        let router1 = []
        let router2 = []
        window.sessionStorage.setItem("MENU", JSON.stringify([]))
        window.sessionStorage.setItem("MENU2", JSON.stringify([]))
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].permission == 'console') {
            // 缓存控制台数据
            window.sessionStorage.setItem("MENU", JSON.stringify(res.data[i].children));
            router1 = res.data[i].children
            show = true
          }
          if (res.data[i].permission == 'operation') {
            // 缓存操作台数据
            window.sessionStorage.setItem("MENU2", JSON.stringify(res.data[i].children));
            router2 = res.data[i].children
          }
        }
        // if (!show) {
        //   handleMessage('暂无控制台权限')
        //   return
        // }
        setmenu(getmenudata(router1), getmenudata(router2))
        // 判断登录的是否为公司用户
        // if (JSON.parse(window.sessionStorage.user).user_info.companyId) {
        //   router.push({ path: "/" });
        // } else {
        //   router_push_name('operation_worktable',undefined,undefined,true)
        // }
        if(type=='login'){
          if(store.state.contractId){
            router_push_name('sign_details', { id: store.state.contractId,type:1 }, undefined, true)
          }else{
            router_push_name('operation_worktable',undefined,undefined,true)
          }
        }else{
          store.state.backRouteName = 'operation_worktable'
          sessionStorage.RouteInfo='operation_worktable'
          sessionStorage.RouteParams=JSON.stringify({}) 
          // 刷新页面当前
          location.reload();
        }
      }).catch(() => {
        window.sessionStorage.removeItem("token");
        window.sessionStorage.removeItem("user");
        window.sessionStorage.removeItem("roles");
      })
    }
    //添加路由 与 菜单-+ 工作台路由
    function setmenu(getRouters, getRouters2 = []) {
      let showMenus = [{
        path: "",
        component: () => import("@/components/nav/nav.vue"),
        name: "console",
        meta: {
          name: "控制台",
        },
        children: [...getRouters, ...hideRoutes]
      }, {
        path: "/operation",
        component: () => import("@/components/nav/navLayout.vue"),
        name: "operation",
        meta: {
          name: "操作台",
        },
        children: [...getRouters2, ...hideRoutes2]
      }]
      // console.log('showMenus',showMenus)
      //不需要动态的路由（深拷贝）
      let MainContainer = clone(showMenus).find((v) => v.path === "");
      let MainContainer2 = clone(showMenus).find((v) => v.path === "/operation");
      // 增加vuex 菜单侧边栏组件数据
      commit("SET_MENU", getRouters);
      commit("SET_MENU2", getRouters2);
      // 递归为所有有子路由的路由设置第一个children.path为默认路由
      setDefaultRoute([MainContainer]);
      setDefaultRoute([MainContainer2]);
      // 添加路由
      router.addRoute(MainContainer);
      router.addRoute(MainContainer2);
    }
  },
};

export const loadView = (path) => { // 路由懒加载
  if (path)
    return () => import(`@/views${path}`)
  else
    return view
};
// 递归处理后端数据
function getmenudata(data = []) {
  let returndata = []
  for (let i = 0; i < data.length; i++) {
    let pushdata = {
      name: data[i].permission,
      path: data[i].permission || '',
      component: loadView(data[i].path),
      meta: {
        name: data[i].name,
      },
    }
    if ('children' in data[i]) {
      if (data[i].children.length > 0) {
        pushdata.children = data[i].children
      }
    }
    if (data[i].icon) {
      pushdata.meta.icon = data[i].icon
      pushdata.meta.iconAc = data[i].iconAc
    }
    returndata.push(pushdata)
    if ('children' in data[i]) {
      // 如果树形子集的长度大与0 重新调取该方法获取子集数据
      if (data[i].children.length > 0) {
        returndata[i].children = getmenudata(returndata[i].children)
      }
    }
  }
  return returndata
}
export default actions;
