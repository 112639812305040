export const clone = function (data) {
  const t = typeOf(data);
  let o;

  if (t === "array") {
      o = [];
  } else if (t === "object") {
      o = {};
  } else {
      return data;
  }

  if (t === "array") {
      for (let i = 0; i < data.length; i++) {
          o.push(clone(data[i]));
      }
  } else if (t === "object") {
      for (const i in data) {
          o[i] = clone(data[i]);
      }
  }
  return o;
}
function typeOf(obj) {
    const toString = Object.prototype.toString;
    const map = {
        "[object Boolean]": "boolean",
        "[object Number]": "number",
        "[object String]": "string",
        "[object Function]": "function",
        "[object Array]": "array",
        "[object Date]": "date",
        "[object RegExp]": "regExp",
        "[object Undefined]": "undefined",
        "[object Null]": "null",
        "[object Object]": "object",
    };
    return map[toString.call(obj)];
}
/**
 *
 * @param {Array} routes 用户过滤后的路由
 *
 * 递归为所有有子路由的路由设置第一个children.path为默认路由
 */
export function setDefaultRoute(routes) {
    routes.forEach((v, i) => {
        if (v.children && v.children.length > 0) {
            v.redirect = { name: v.children[0].name }
            setDefaultRoute(v.children)
        }
    })
}