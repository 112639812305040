import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store/index";
// import {httpToken} from "@/utils/request";
// import { DynamicRoutes } from "@/router/route";
//进度条
import NProgress from 'nprogress' ;
import 'nprogress/nprogress.css';
// 定义静态路由
const routes: Array<RouteRecordRaw> = [
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/login/login.vue"),
    },
    {
        path: "/register",
        name: "register",
        component: () => import("@/views/login/register.vue"),
    },
    {
        path: "/realname",
        component: () => import("@/views/operation/realname/index.vue"),
        name: "realname",
        meta: {
            name: "实名认证",
        },
        children: [
            {
                path:"/realname/boot",
                component : () => import("@/views/operation/realname/boot.vue"),
                name:"realname_boot",
                meta: {
                    // icon:'shezhi',
                    name: "实名认证引导页",
                },
            },
            {
                path:"/realname/enterprise",
                component : () => import("@/views/operation/realname/enterprise.vue"),
                name:"realname_enterprise",
                meta: {
                    name: "企业实名认证页",
                },
            },
            {
                path:"/realname/individual",
                component : () => import("@/views/operation/realname/individual.vue"),
                name:"realname_individual",
                meta: {
                    name: "个人实名认证页",
                },
            },
            {
                path:"/realname/record",
                component : () => import("@/views/operation/realname/record.vue"),
                name:"realname_record",
                meta: {
                    name: "认证记录页",
                },
            },
        ]
    },
];
// 创建路由
const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to: any, from: any, next: any) => {
    console.log('当前路由信息打印to',to)
    console.log('当前路由信息打印from',from)
    // console.log('to-->from',to,from)
    NProgress.start();
    //未登录
    if (!window.sessionStorage.getItem("token") && to.path !== "/login" && to.path !== "/register") {
        return next({ path: "/login" });
    };
    //已登录
    if(window.sessionStorage.getItem("token")){ 
        if(to.path == "/login"){
            return next({ path: "/" })
        }
    }
    next();
});
router.afterEach((to: any, from: any, next: any) => {
    NProgress.done();
    //目前左边导航选中的active
    store.commit("SET_CURRENT_MENU", to.name);
});
/* 固定的路由 */
/*
    meta:{
        name:侧边栏名字,
        icon:侧边栏图标,
        hide:是否在侧边栏隐藏
    }
*/ 


export default router;
